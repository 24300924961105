import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { green, red } from '@material-ui/core/colors';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const TotalCustomers = ({ currentUsers, previousMonth, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  let procent = 0;
  let positive = true;
  let noDifference = true;

  if (previousMonth < currentUsers) {
    positive = true;
    noDifference = false;
    procent = (currentUsers / previousMonth) * 100;
  } else if (previousMonth > currentUsers) {
    positive = false;
    noDifference = false;
    procent = 100 - (currentUsers / previousMonth) * 100;
  } else {
    noDifference = true;
  }

  return (
    <Card
      sx={{ height: '100%' }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {t('dashboard.totalUsers')}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {currentUsers}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        { !noDifference && previousMonth !== 0
        && (
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {positive
            ? <ArrowUpwardIcon sx={{ color: green[900] }} /> : <ArrowDownwardIcon sx={{ color: red[900] }} />}
          <Typography
            sx={{
              color: positive ? green[900] : red[900],
              mr: 1
            }}
            variant="body2"
          >
            {procent}
            %
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {t('dashboard.lastMonth')}
          </Typography>
        </Box>
        )}
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  currentUsers: PropTypes.number.isRequired,
  previousMonth: PropTypes.number.isRequired,
};

export default TotalCustomers;
