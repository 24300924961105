import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProjectList from 'src/pages/ProjectList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import CompanyList from './pages/CompanyList';
import Job from './pages/Job';
import PlanAndPayment from './pages/PlanAndPayment';
import Company from './pages/Company';
import Project from './pages/Project';
import Archived from './pages/Archived';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'archived', element: <Archived /> },
      { path: 'projects', element: <ProjectList /> },
      { path: 'companies', element: <CompanyList /> },
      { path: 'job/:id', element: <Job /> },
      { path: 'plan', element: <PlanAndPayment /> },
      { path: 'project/:id', element: <Project /> },
      { path: 'company/:id', element: <Company /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to={sessionStorage.getItem('user') ? '/app/dashboard' : '/login'} /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
