import { Helmet } from 'react-helmet';
import React from 'react';
import { Alert, AlertTitle, Typography, Card, CardContent, CardHeader, TextField, Divider, Button, Box, Container, Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { tiers } from '../common/tiers';

import {
  CreditCard as CreditCardIcon,
} from 'react-feather';
import auth from '../firebase';

class Trial extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      companyData: {},
      subscriptions: [],
      loaded: false,
      selectedTier: '',
    };
  }

  async getCompanyData(usrData) {
    auth.app.firestore()
      .collection('companies')
      .doc(usrData.company)
      .get()
      .then((compSnap) => {
        const companyData = { ...compSnap.data(), ...{ id: compSnap.id } };
        this.setState({ companyData });
        return auth.app.firestore()
        .collection('plan')
        .doc('subscriptions')
        .collection('subscriptions')
        .get();
      }).then((subs) => {
        const subscriptions = subs.docs.map(doc => {
          return { ...doc.data(), ...{ id: doc.id } };
        });
        this.setState({ subscriptions, loaded: true });
      })
  }

  componentDidMount() {
    if (sessionStorage && sessionStorage.user) {
      const usrData = JSON.parse(window.sessionStorage.user);
      this.getCompanyData(usrData);
    } else {
      window.addEventListener('user-data-loaded', () => {
        if (window.sessionStorage && window.sessionStorage.user && userData === null) {
          const usrData = JSON.parse(window.sessionStorage.user);
          this.getCompanyData(usrData);
        }
      }, { once: true });
    }
  }

  async selectSubscription() {
    const { selectedTier, companyData } = this.state;
    await auth.app.firestore()
      .collection('companies')
      .doc(companyData.id)
      .update({ subscription: selectedTier.toLowerCase() });
    companyData.subscription = selectedTier.toLowerCase();
    this.setState({ companyData });
  }

  async startTrial() {
    const { companyData } = this.state;
    const dateIn14Days = new Date();
    dateIn14Days.setDate(dateIn14Days.getDate()+14);
    await auth.app.firestore()
      .collection('companies')
      .doc(companyData.id)
      .update({ endTrialPeriod: dateIn14Days });
    window.location.reload(true);
  }

  async startPlan() {
    const { companyData } = this.state;
    const dateMinus1 = new Date();
    dateMinus1.setDate(dateMinus1.getDate()-1);
    await auth.app.firestore()
      .collection('companies')
      .doc(companyData.id)
      .update({ subscriptionConfirmed: true, subscriptionStartDate: new Date(), endTrialPeriod: dateMinus1 });
    window.location.reload(true);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const { companyData, subscriptions, loaded, selectedTier } = this.state;
    const subscription = subscriptions?.find((s) => s.id === companyData.subscription);
    return (
    <>
        <Helmet>
          <title>
            {t('common.account')}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        {subscription && loaded ?
        (<Box
          sx={{
            backgroundColor: 'background.default',
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            minHeight:"100vh",
            marginTop: -10
          }}
        >
          <Card sx={{ height: '60%', width: '60%', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CreditCardIcon style={{alignSelf: 'center'}} size="70" />
            <Typography
              align= 'center'  variant='h2' margin={5}
            >Confirm your subscription</Typography>
            <Grid
              item
              key={subscription.name}
              alignSelf='center'
              paddingBottom={5}
              xs={12}
              md={4}
            >
              <Card border sx={{height: 220 }}>
                <CardHeader
                  title={subscription.name?.toUpperCase()}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: '#EE7022',
                    color: 'white',
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                    €{subscription.cost}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /user/mo
                    </Typography>
                  </Box>
                  <ul>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        key={'minimum'}
                      >
                        Minimum {subscription.minimum} users
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        key={'max'}
                      >
                        Max {subscription.users} users
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        key={'support'}
                      >
                        Support response within {subscription.support}H
                      </Typography>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2
              }}
            >
              <Button
                color="primary"
                variant="contained"
                fullWidth
                sx={{marginLeft: 5, marginRight: 5}}
                onClick={() => this.startPlan()}
              >
                Start chosen plan
              </Button>
              {companyData.endTrialPeriod === undefined &&
              <Button
                style={{ backgroundColor: 'lightgrey', color: 'black' }}
                fullWidth
                sx={{marginLeft: 5, marginRight: 5}}
                variant="contained"
                onClick={() => this.startTrial()}
              >
                Start 14 day trial
              </Button>}
            </Box>
          </Card>
        </Box>) : loaded ? (
          <Box
          sx={{
            backgroundColor: 'background.default',
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            minHeight:"100vh",
            marginTop: -10
          }}
        >
          <Card sx={{ height: '60%', width: '60%', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CreditCardIcon style={{alignSelf: 'center'}} size="70" />
            <Typography
              align= 'center'  variant='h2' margin={5}
            >Choose your company subscription</Typography>
              <Alert severity="info" variant="outlined" sx={{marginBottom: 5, marginLeft: 3, marginRight: 3}} color="warning">
              <AlertTitle>Info</AlertTitle>
                You will be able to use a <strong>14 day trial</strong> — <a style={{color:'#EE7022'}} href="https://zetwerk.app/pricing/">Pricing details</a>
              </Alert>
              <Container component="main">
                <Grid container spacing={3} paddingBottom={3} alignItems="flex-end">
                  {tiers.map((tier) => (
                    // Enterprise card is full width at sm breakpoint
                    <Grid
                      item
                      key={tier.title}
                      xs={12}
                      md={4}
                    >
                      <Card border sx={{height: 220, cursor: 'grab' }} onClick={() => this.setState({ selectedTier: tier.title})}>
                        <CardHeader
                          title={tier.title}
                          titleTypographyProps={{ align: 'center' }}
                          subheaderTypographyProps={{
                            align: 'center',
                          }}
                          sx={{
                            backgroundColor: (theme) =>
                            selectedTier === tier.title
                              ? '#EE7022'
                              : theme.palette.grey[200],
                            color: (theme) =>
                            selectedTier === tier.title
                              ? 'white'
                              : 'black',
                          }}
                        />
                        <CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'baseline',
                              mb: 2,
                            }}
                          >
                            <Typography component="h2" variant="h3" color="text.primary">
                            €{tier.price}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                              /user/mo
                            </Typography>
                          </Box>
                          <ul>
                            {tier.description.map((line) => (
                              <Typography
                                variant="subtitle1"
                                align="center"
                                key={line}
                              >
                                {line}
                              </Typography>
                            ))}
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2
              }}
            >
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => this.selectSubscription()}
                sx={{marginLeft: 1, marginRight: 1}}
              >
                Save subscription
              </Button>
            </Box>
          </Card>
        </Box>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(Trial);
