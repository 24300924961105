// import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  // Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';
import auth from '../firebase';

const DashboardNavbar = ({ onMobileNavOpen, trailPeriodEnd, ...rest }) => {
  // const [notifications] = useState([]);

  function logout() {
    sessionStorage.removeItem('user');
    auth.signOut().then(() => window.location.assign('/login'));
  }

  let daysLeft = null;

  if (trailPeriodEnd) {
    const today = new Date();
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = trailPeriodEnd.getTime() - today.getTime();
    // Calculating the no. of days between two dates
    const diff = Math.round(diffInTime / oneDay);
    if (diff >= 0) {
      daysLeft = Math.round(diffInTime / oneDay);
    }
  }

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        {daysLeft && <Typography type='h1' width='100%' align='center' fontWeight='bold'> TRIAL VERSION - {daysLeft} DAYS LEFT</Typography>}
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
  </IconButton> */}
          <IconButton onClick={() => logout()} color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
