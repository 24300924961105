export const tiers = [
    {
      title: 'Individual',
      price: '45',
      description: [
      '1 User',
      'Support in 48h',
      ],
    },
    {
      title: 'Team',
      price: '35',
      description: [
      'Up to 20 Users',
      'Minimum 2 Users',
      'Support in 24h',
      ],
    },
    {
      title: 'Enterprise',
      price: '25',
      description: [
        '20+ Users',
        'Minimum 20 users',
        'Support in 6h',
      ]
    }
  ];
  