import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CompanyProfileDetails = ({
  companyData, edit, cancelButton, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState({
    name: companyData.name || '',
    email: companyData.email || '',
    phone: companyData.phone || '',
    address: companyData.address || '',
    VAT: companyData.VAT || '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      noValidate
    >
      <Card>
        <CardHeader
          subheader={t('company.editInfo')}
          title={t('company.profile')}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label={t('common.companyName')}
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={t('common.emailAddress')}
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={t('common.phone')}
                name="phone"
                onChange={handleChange}
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={t('common.address')}
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label={t('company.vat')}
                name="VAT"
                onChange={handleChange}
                required
                value={values.VAT}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {cancelButton && (
          <Button
            style={{ backgroundColor: 'lightgrey', color: 'black' }}
            sx={{ marginRight: 2 }}
            variant="contained"
            onClick={() => rest.oncancel()}
          >
            {t('common.cancel')}
          </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => (edit ? rest.changeuser(values) : rest.handler(values))}
          >
            {t('common.save')}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CompanyProfileDetails.propTypes = {
  companyData: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  cancelButton: PropTypes.bool.isRequired,
};

export default CompanyProfileDetails;
