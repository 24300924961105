import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import Budget from 'src/components/dashboard//Budget';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestUsers from 'src/components/dashboard/LatestUsers';
import Sales from 'src/components/dashboard//Sales';
import TotalProjects from 'src/components/dashboard//TotalProjects';
import TotalJobs from 'src/components/dashboard//TotalJobs';
import TotalCustomers from 'src/components/dashboard//TotalCustomers';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import TrafficByDevice from 'src/components/dashboard//TrafficByDevice';
import auth from '../firebase';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      userData: {}, projects: [], projectsPreviousMonth: 0, companies: [], companiesPreviousMonth: 0, users: [], usersPreviousMonth: 0, jobs: []
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
      }
    });
  }

  getUsersData(userData) {
    const promises = [];
    promises.push(this.setUsers(userData));
    const usersList = auth.app.functions().httpsCallable('getuserlist');
    promises.push(usersList());
    Promise.all(promises).then(([, authData]) => {
      setTimeout(() => {
        const { users } = this.state;
        const currentUserData = JSON.parse(JSON.stringify(users));
        const authPersons = authData.data;
        currentUserData.forEach((person, index) => {
          const userEntry = authPersons.find((p) => p.uid === person.id);
          if (userEntry) {
            currentUserData[index] = { ...person, ...userEntry };
          }
        });
        this.setState({ users: currentUserData.sort((a, b) => moment(b.metadata.creationTime) - moment(a.metadata.creationTime)) });
      }, 100);
    }).catch(() => null);
  }

  async getUserData() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const userData = userSnap.data();
        this.setState({ userData });
        this.setProjects(userData);
        this.setCompanies(userData);
        this.getUsersData(userData);
        this.setJobs(userData);
        sessionStorage.setItem('user', JSON.stringify({ ...userData, ...{ id: auth.currentUser.uid } }));
        const event = document.createEvent('Event');
        event.initEvent('user-data-loaded', true, true);
        window.dispatchEvent(event);
      });
  }

  async setProjects(userData) {
    if (userData.role === 'super_admin') {
      auth.app.firestore().collection('projects').get().then((response) => {
        const projects = [];
        response.docs.forEach((doc) => {
          projects.push({ ...doc.data(), ...{ id: doc.id } });
        });
        this.setState({ projects });
      });
      auth.app.firestore().collection('analytics').doc('projects').get()
        .then((snap) => {
          this.setState({ projectsPreviousMonth: snap.data().previousMonth });
        });
    } else {
      auth.app.firestore().collection('projects').where('customer', '==', userData.company).get()
        .then((response) => {
          const projects = [];
          response.docs.forEach((doc) => {
            projects.push({ ...doc.data(), ...{ id: doc.id } });
          });
          this.setState({ projects });
        });
    }
  }

  setUsers(userData) {
    if (userData.role === 'super_admin') {
      return auth.app.firestore().collection('users').get().then((response) => {
        const users = [];
        const promises = [];
        response.docs.forEach((doc) => {
          if (doc.data().avatar) {
            promises.push(auth.app.storage().ref(doc.data().avatar).getDownloadURL().then((url) => {
              users.push({ ...doc.data(), ...{ id: doc.id, avatarUrl: url } });
            })
              .catch(() => users.push({ ...doc.data(), ...{ id: doc.id } })));
          } else {
            users.push({ ...doc.data(), ...{ id: doc.id } });
          }
        });
        return Promise.all(promises).then(() => {
          this.setState({ users });
          return auth.app.firestore().collection('analytics').doc('users').get()
            .then((snap) => {
              this.setState({ usersPreviousMonth: snap.data().previousMonth });
            });
        });
      });
    }
    return auth.app.firestore().collection('users').where('company', '==', userData.company).get()
      .then((response) => {
        const users = [];
        const promises = [];
        response.docs.forEach((doc) => {
          if (doc.data().avatar) {
            promises.push(auth.app.storage().ref(doc.data().avatar).getDownloadURL().then((url) => {
              users.push({ ...doc.data(), ...{ id: doc.id, avatarUrl: url } });
            })
              .catch(() => users.push({ ...doc.data(), ...{ id: doc.id } })));
          } else {
            users.push({ ...doc.data(), ...{ id: doc.id } });
          }
        });
        return Promise.all(promises).then(() => {
          this.setState({ users });
        });
      });
  }

  async setJobs(userData) {
    if (userData.role === 'super_admin') {
      auth.app.firestore().collection('jobs').get().then((response) => {
        const jobs = [];
        response.docs.forEach((doc) => {
          jobs.push({ ...doc.data(), ...{ id: doc.id } });
        });
        this.setState({ jobs });
      });
    } else {
      let query = auth.app.firestore().collection('jobs');
      query = query.where('company', '==', userData.company);
      if (userData.role === 'factory_user') {
        query = query.where('factoryStatus', '!=', null);
      }
      query.get()
        .then((response) => {
          const jobs = [];
          response.docs.forEach((doc) => {
            jobs.push({ ...doc.data(), ...{ id: doc.id } });
          });
          this.setState({ jobs });
        });
    }
  }

  async setCompanies(userData) {
    if (userData.role === 'super_admin') {
      auth.app.firestore().collection('companies').get().then((response) => {
        const companies = [];
        response.docs.forEach((doc) => {
          companies.push({ ...doc.data(), ...{ id: doc.id } });
        });
        this.setState({ companies });
      });
      auth.app.firestore().collection('analytics').doc('companies').get()
        .then((snap) => {
          this.setState({ companiesPreviousMonth: snap.data().previousMonth });
        });
    }
  }

  render() {
    const {
      userData, projects, projectsPreviousMonth, companies, companiesPreviousMonth, users, usersPreviousMonth, jobs
    } = this.state;

    const factoryUser = userData.role === 'factory_user';
    return (
      <>
        <Helmet>
          <title>
            Dashboard | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            {userData.role && <Grid
              container
              spacing={3}
            >
              {userData.role === 'super_admin'
              && (
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Budget currentCompanies={companies.length} previousMonth={companiesPreviousMonth} />
              </Grid>
              )}
              {!factoryUser && <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <TotalCustomers currentUsers={users.length} previousMonth={usersPreviousMonth} />
              </Grid>}
              {!factoryUser && <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <TotalProjects currentProjects={projects.length} previousMonth={projectsPreviousMonth} />
              </Grid>}
              {userData.role === 'company_admin'
              && (
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <TotalJobs currentJobs={jobs.length} />
              </Grid>
              )}
              {!factoryUser && <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <TotalProfit currentJobs={jobs} sx={{ height: '100%' }} />
              </Grid>}
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <LatestOrders currentJobs={jobs} isFactory={factoryUser} />
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <TrafficByDevice jobs={jobs} isFactory={factoryUser} sx={{ height: '100%' }} />
              </Grid>
              {!factoryUser && <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <LatestUsers users={users} />
              </Grid>}
              {!factoryUser && <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <Sales jobs={jobs}/>
              </Grid>}
            </Grid>}
          </Container>
        </Box>
      </>
    );
  }
}

export default Dashboard;
