import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AttachMoneyIcon from '@material-ui/icons/EuroSymbol';

const TotalProfit = ({ currentJobs, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const orderedJobs = currentJobs.filter((e) => e.ordered) || [];
  const d = new Date();
  const month = [];
  month[0] = 'JANUARY';
  month[1] = 'FEBRUARY';
  month[2] = 'MARCH';
  month[3] = 'APRIL';
  month[4] = 'MAY';
  month[5] = 'JUNE';
  month[6] = 'JULY';
  month[7] = 'AUGUST';
  month[8] = 'SEPTEMBER';
  month[9] = 'OCTOBER';
  month[10] = 'NOVEMBER';
  month[11] = 'DECEMBER';
  const n = month[d.getMonth()];
  let cost = 0;
  orderedJobs.forEach((j) => {
    if (j.cost) {
      cost += j.cost;
    }
  });
  cost = Math.round((cost / 100) * 100);
  return (
    <Card
      sx={{ height: '100%' }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid width="65%" item>
            <Typography
              color="textSecondary"
              style={{ wordWrap: 'normal' }}
              gutterBottom
              variant="h6"
            >
              {t('planAndPayment.costJobs')}
              {' - '}
              {n}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              €
              {cost}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.propTypes = {
  currentJobs: PropTypes.array.isRequired,
};

export default TotalProfit;
