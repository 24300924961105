import { useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut, Chart } from 'react-chartjs-2';
import moment from 'moment';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Container,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  TablePagination,
  Typography,
  Dialog,
  Grid,
  Tooltip,
  useTheme,
  colors,
  FormControlLabel
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import auth from '../../firebase';
import {statusses, factoryStatusses} from '../../common/status.js'

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw() {
    // eslint-disable-next-line prefer-rest-params
    originalDoughnutDraw.apply(this, arguments);

    const { chart } = this.chart;
    const { ctx } = chart;
    const { width } = chart;
    const { height } = chart;

    const fontSize = (height / 114).toFixed(2);
    ctx.font = `${fontSize}em Verdana`;
    ctx.textBaseline = 'middle';

    const { text } = chart.config.data;
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    ctx.fillText(text, textX, textY);
  }
});

const ProjectListResults = ({
  projects, userData, companies, ...rest
}) => {
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [code, setCode] = useState('');
  const [customer, setCustomer] = useState('');
  const [limit, setLimit] = useState(8);
  const [page, setPage] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addAllUsers, setAddAllUsers] = useState(true);
  const sorts = [{
    id: 'name',
    name: 'jobsAndProjects.projectName',
  }, {
    id: 'customer',
    name: 'company.creation',
  }, {
    id: 'createdDate',
    name: 'account.company',
  }];

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = projects.map((c) => c.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const search = (event) => {
    const txt = event.target.value.toLowerCase();
    setSearchInput(txt);
    if (txt && txt.length > 0) {
      setFilteredCustomers(projects.filter((c) => c.name.toLowerCase().includes(txt) || c.id.toLowerCase().includes(txt)));
    } else {
      setFilteredCustomers([]);
    }
  };

  function compare(a, b, type, ord) {
    const str = (typeof a[type] === 'string' || a[type] instanceof String);
    const aVar = str ? a[type].toLowerCase() : a[type];
    const bVar = str ? b[type].toLowerCase() : b[type];
    if (aVar < bVar) {
      return ord ? -1 : 1;
    }
    if (aVar > bVar) {
      return ord ? 1 : -1;
    }
    return 0;
  }

  function changeSort(type) {
    if (type) {
      if (orderBy === type) {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(type);
        const newProjects = projects;
        newProjects.sort((a, b) => compare(a, b, type, newOrder === 'asc'));
        setFilteredCustomers(newProjects);
      } else {
        setOrderBy(type);
        const newProjects = projects;
        newProjects.sort((a, b) => compare(a, b, type, order === 'asc'));
        setFilteredCustomers(newProjects);
      }
    }
  }

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  /*
  CHANGE TO DELETE PROJECTS
  --> DELETE JOBS & DRAWINGS (UNLESS SAVED AS FAVORITE)
  --> DELETE PROJECT FROM USER PROJECTS
  --> DELETE PROJECT FROM COMPANY PROJECTS
  --> DELETE PROJECT ITSELF
  */
  function deleteUsers() {
    setLoading(true);
    const promises = [];
    selectedCustomerIds.forEach((uid) => {
      const proj = projects.find((p) => p.id === uid);
      proj.users.forEach((usr) => {
        promises.push(auth.app.firestore().collection('users').doc(usr).get()
          .then((snap) => {
            const newProjects = snap.data().projects.filter((p) => selectedCustomerIds.indexOf(p) === -1);
            return snap.ref.update({ projects: newProjects });
          }));
      });
      proj.jobs.forEach((job) => {
        promises.push(auth.app.firestore().collection('jobs').doc(job).delete());
      });
      promises.push(auth.app.firestore().collection('companies').doc(proj.customer).get()
        .then((s) => {
          const compProjects = s.data().projects.filter((p) => selectedCustomerIds.indexOf(p) === -1);
          s.ref.update({ projects: compProjects });
        }));
    });
    Promise.all(promises).finally(() => {
      selectedCustomerIds.forEach((uid) => {
        auth.app.firestore().collection('projects').doc(uid).delete();
      });
      rest.handler(projects.filter((c) => !selectedCustomerIds.includes(c.id)));
      setLoading(false);
      setSelectedCustomerIds([]);
    });
  }

  function loadNewProjectDetails() {
    setLoading(false);
    setModalOpened(true);
    setCustomer(companies[0]);
  }

  async function addProject() {
    setLoading(true);
    setModalOpened(false);
    let users = [];
    if (addAllUsers && userData.role === 'company_admin') {
      const usersDoc = await auth.app.firestore()
        .collection('users')
        .where('company', '==', userData.company)
        .get();

      users = usersDoc.docs.map((u) => ({ ...u.data(), ...{ id: u.id } }));
    }
    return auth.app.firestore().collection('projects').doc(code).set({
      address,
      customer,
      created: new Date(),
      files: [],
      gallery: [],
      image: '',
      jobs: [],
      name,
      status: 'new',
      summary: description,
      users: users.map((usr) => usr.id)
    })
      .then(() => {
        const promises = [];
        users.forEach((u) => {
          const notificationCall = auth.app.functions().httpsCallable('sendusernotification');
          promises.push(notificationCall({
            userId: u.id,
            projectId: code,
            projectName: name,
            message: `You have been added to project ${name}`
          }));
          promises.push(auth.app.firestore()
            .collection('users')
            .doc(u.id)
            .update({ projects: u.projects.concat([code]) }));
        });
        const newProjects = userData.projects;
        newProjects.push(code);
        promises.push(auth.app.firestore().collection('users').doc(auth.currentUser.uid).update({ projects: newProjects })
          .then(() => auth.app.firestore().collection('companies').doc(customer).get()
            .then((s) => {
              const compProjects = s.data().projects;
              compProjects.push(code);
              s.ref.update({ projects: compProjects });
            })));
        return Promise.all(promises).then(() => {
          setLoading(false);
          rest.refresh();
        });
      });
  }

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    elements: {
      center: {
        text: 'Red is 2/3 the total numbers',
        color: '#FF6384', // Default is #000000
        fontStyle: 'Arial', // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25 // Default is 25 (in px), used for when text wraps
      }
    }
  };

  return (
    <Container maxWidth={false}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {selectedCustomerIds && selectedCustomerIds.length > 0
          && (
          <Button
            color="error"
            variant="contained"
            onClick={() => deleteUsers()}
          >
            {t('jobsAndProjects.delete')}
          </Button>
          ) }
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 12 }}
            onClick={() => loadNewProjectDetails()}
          >
            {t('jobsAndProjects.add')}
          </Button>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ maxWidth: 500, width: '100%' }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={search}
                  placeholder={t('jobsAndProjects.search')}
                  variant="outlined"
                />
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'flex-end', width: '100%'
              }}
              >
                {!isMobile && (
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                  <Typography
                    sx={{ maxWidth: 200 }}
                    alignSelf="center"
                    color="textPrimary"
                    variant={isMobile ? 'body2' : 'body1'}
                  >
                    Select All
                  </Typography>
                  <Checkbox
                    checked={selectedCustomerIds.length === projects.length}
                    color="primary"
                    indeterminate={
      selectedCustomerIds.length > 0
      && selectedCustomerIds.length < projects.length
    }
                    onChange={handleSelectAll}
                  />
                </Box>
                )}
                <TextField
                  sx={{ maxWidth: 200, width: '100%', marginLeft: 5 }}
                  fullWidth
                  name="sorting"
                  required
                  value={orderBy}
                  onChange={(e) => changeSort(e.target.value)}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {sorts.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                    >
                      {t(option.name)}
                    </option>
                  ))}
                </TextField>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box sx={{ pt: 3, backgroundColor: 'transparent' }} color="transparent">
        <Card
          sx={{
            margin: -1, backgroundColor: 'transparent', border: 'none', boxShadow: 'none'
          }}
          color="transparent"
        >
          <Grid container style={{ opacity: loading ? 0.3 : 1 }}>
            {(searchInput.length > 0 ? filteredCustomers : projects).slice(0 + page * limit, limit * (page + 1)).map((project) => {
              const percentages = (userData.role === 'factory_user' ? factoryStatusses : statusses).map((status, index) => ({
                title: status,
                value: Math.round((project.dataset.datasets[0].data[index] / project.dataset.text) * 100) || 0,
                color: project.dataset.datasets[0].backgroundColor[index],
              }));
              return (
                <Tooltip key={`index-${project.id}-project`} enterDelay={400} leaveDelay={400} disableHoverListener={!project.disabled} title={project.disabled ? 'The company of this project has been disabled, please enable the company to continue the project.' : ''} placement="bottom">
                  <Grid
                    sx={{
                      padding: 1, margin: 0, opacity: project.disabled ? 0.5 : 1, cursor: project.disabled ? 'auto' : 'pointer'
                    }}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <Box sx={{
                      paddingLeft: 2, paddingRight: 2, paddingTop: 1, paddingBottom: 1, position: 'relative', backgroundColor: 'white', border: '1px solid rgba(224, 224, 224, 1)'
                    }}
                    >
                      <Checkbox
                        color="primary"
                        disabled={project.disabled}
                        checked={selectedCustomerIds.indexOf(project.id) !== -1}
                        onChange={(event) => handleSelectOne(event, project.id)}
                        sx={{
                          position: 'absolute', alignSelf: 'flex-end', right: 3, top: 0
                        }}
                        value="true"
                      />
                      <Box
                        onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          marginBottom: 1,
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 18, width: '100%', alignContent: 'center' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          {project.name}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}
                        sx={{
                          justifyContent: 'space-between',
                          display: 'flex',
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignContent: 'center' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          Project Id
                        </Typography>
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignContent: 'center' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          {project.id}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}
                        sx={{
                          justifyContent: 'space-between',
                          display: 'flex',
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignSelf: 'flex-start' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          Company
                        </Typography>
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignSelf: 'flex-end' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          {project.customer}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}
                        sx={{
                          justifyContent: 'space-between',
                          display: 'flex',
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignSelf: 'flex-start' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          Users
                        </Typography>
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignSelf: 'flex-end' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          {project.users.length}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}
                        sx={{
                          justifyContent: 'space-between',
                          display: 'flex',
                          marginBottom: 1,
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignSelf: 'flex-start' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          Creation date
                        </Typography>
                        <Typography
                          color="textPrimary"
                          sx={{ fontSize: 12, alignSelf: 'flex-end' }}
                          variant={isMobile ? 'body2' : 'body1'}
                        >
                          {moment(project.createdDate).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => (project.disabled ? null : window.location.assign(`project/${project.id}`))}
                        xs={12}
                        sm={6}
                        md={3}
                        paddingBottom={3}
                      >
                        <Doughnut
                          redraw
                          data={project.dataset}
                          options={options}
                        />
                      </Box>
                    </Box>

                  </Grid>
                </Tooltip>
              );
            })}
          </Grid>
          <Box padding={1}>
            <TablePagination
              style={{ backgroundColor: 'white' }}
              component="div"
              labelRowsPerPage={isMobile ? t('company.per') : t('company.rowsPerPage')}
              count={searchInput.length > 0 ? filteredCustomers.length : projects.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[4, 8, 16]}
            />
          </Box>
        </Card>
      </Box>
      <Dialog
        onBackdropClick={() => setModalOpened(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 20,
          minWidth: '100vw',
          justifyContent: 'center'
        }}
        open={modalOpened}
      >
        <Grid
          container
          width={isMobile ? '90vw' : null}
          spacing={0}
          sx={{ backgroundColor: 'white' }}
        >
          <form
            noValidate
          >
            <Card>
              <CardHeader
                subheader={t('jobsAndProjects.projectDetails')}
                title="Project"
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.projectName')}
                      name="projectname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.projectDescription')}
                      name="projectdescripton"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.address')}
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.projectCode')}
                      name="projectcode"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      required
                      helperText={t('jobsAndProjects.codeExample')}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('jobsAndProjects.selectCompany')}
                      name="company"
                      required
                      select
                      value={customer}
                      onChange={(e) => setCustomer(e.target.value)}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {companies.map((option) => (
                        <option
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                {userData.role === 'company_admin' && (<Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <FormControlLabel control={<Checkbox
                    checked={addAllUsers}
                    onChange={(e) => setAddAllUsers(e.target.checked)} sx={{
                        color: "#EE7022",
                        '&.Mui-checked': {
                          color: "#EE7022",
                        },
                    }} />} label={t('jobsAndProjects.addAllUsers')} />
                  </Grid>)}
                <Button
                  style={{ backgroundColor: 'lightgrey', color: 'black' }}
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  onClick={() => setModalOpened(false)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => addProject()}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Card>
          </form>
        </Grid>
      </Dialog>
    </Container>
  );
};

ProjectListResults.propTypes = {
  projects: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired
};

export default ProjectListResults;
