import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';

let states = [
  {
    value: 'super_admin',
    label: 'Super Admin',
    super_admin: true,
    company_admin: false,
  },
  {
    value: 'company_admin',
    label: 'Company Admin',
    super_admin: true,
    company_admin: true,
  },
  {
    value: 'app_user',
    label: 'App User',
    super_admin: true,
    company_admin: true,
  },
  {
    value: 'factory_user',
    label: 'Factory User',
    super_admin: true,
    company_admin: true,
  }
];

const AccountProfileDetails = ({
  userData, companies, edit, cancelButton, currentUser, ...rest
}) => {
  const [values, setValues] = useState({
    name: userData.name || '',
    email: userData.email || '',
    mobile: userData.mobile || '',
    company: userData.company || (companies.length > 0 ? companies[0] : ''),
    role: userData.role || 'app_user',
    id: userData.id || '',
  });

  const roleDisabled = !states.find((state) => state.value === values.role && state[currentUser.role] === true);
  if (!roleDisabled) {
    states = states.filter((s) => s[currentUser.role]);
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      noValidate
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First and last name"
                name="name"
                onChange={handleChange}
                required
                disabled={roleDisabled}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                disabled={roleDisabled}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="mobile"
                disabled={roleDisabled}
                onChange={handleChange}
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Company"
                name="company"
                onChange={handleChange}
                required
                select
                disabled={currentUser.role !== 'super_admin'}
                SelectProps={{ native: true }}
                value={values.company}
                variant="outlined"
              >
                {companies.map((option) => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select Role"
                name="role"
                onChange={handleChange}
                required
                disabled={roleDisabled}
                select
                SelectProps={{ native: true }}
                value={values.role}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {cancelButton && (
          <Button
            style={{ backgroundColor: 'lightgrey', color: 'black' }}
            sx={{ marginRight: 2 }}
            variant="contained"
            onClick={() => rest.oncancel()}
          >
            Cancel
          </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            disabled={roleDisabled}
            onClick={() => (edit ? rest.changeuser(values) : rest.handler(values))}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

AccountProfileDetails.propTypes = {
  userData: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  cancelButton: PropTypes.bool.isRequired,
};

export default AccountProfileDetails;
