import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import Trial from '../pages/Trial';
import DashboardSidebar from './DashboardSidebar';
import auth from '../firebase';
import MyContext from '../common/context';

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [paymentChoice, setPaymentChoice] = useState(false);
  const [trailPeriodEnd, setTrialPeriodEnd] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  const getCompanyData = (usrData) => {
    if (usrData.role !== 'super_admin') {
      auth.app.firestore()
      .collection('companies')
      .doc(usrData.company)
      .get()
      .then((companySnap) => {
        const data  = companySnap.data();
        if (data.endTrialPeriod === undefined || (data.endTrialPeriod.toDate() < new Date() && !data.subscriptionConfirmed)) {
          setPaymentChoice(true);
        } else {
          setTrialPeriodEnd(data.endTrialPeriod.toDate());
          auth.app.firestore()
            .collection('plan')
            .doc('subscriptions')
            .collection('subscriptions')
            .doc(data.subscription)
            .get()
            .then((sub) => {
              setSubscriptionPlan(sub.data());
            });
        }
      });
    }
  };

  if (sessionStorage && sessionStorage.user && userData === null) {
    const usrData = JSON.parse(window.sessionStorage.user);
    setUserData(usrData);
    getCompanyData(usrData);
  } else {
    window.addEventListener('user-data-loaded', () => {
      if (window.sessionStorage && window.sessionStorage.user && userData === null) {
        const usrData = JSON.parse(window.sessionStorage.user);
        setUserData(usrData);
        getCompanyData();
      }
    }, { once: true });
  }

  return (
    <MyContext.Provider value={{ subscriptionPlan }}>
      <DashboardLayoutRoot>
        <DashboardNavbar trailPeriodEnd={trailPeriodEnd} onMobileNavOpen={() => setMobileNavOpen(true)} />
        <DashboardSidebar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          paymentChoice={paymentChoice}
          trailPeriodEnd={trailPeriodEnd}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              { paymentChoice ? <Trial /> : <Outlet context={[subscriptionPlan]} />}
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </MyContext.Provider>
  );
};

export default DashboardLayout;
