import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  Avatar,
  ListItemText
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import getInitials from 'src/utils/getInitials';
import PropTypes from 'prop-types';

const LatestUsers = ({
  users, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <Card {...rest}>
      <CardHeader
        subtitle={`${users.length} in total`}
        title={t('dashboard.latestUsers')}
      />
      <Divider />
      <List>
        {users.slice(0, 6).map((user, i) => (
          <ListItem
            divider={i < users.length - 1}
            key={user.id}
          >
            <Avatar
              src={user.avatarUrl}
              sx={{ mr: 2 }}
            >
              {getInitials(user.name)}
            </Avatar>
            <ListItemText
              primary={user.name}
              secondary={`${t('common.created')} ${user.metadata ? moment(user.metadata.creationTime).format('DD/MM/YYYY') : null}`}
            />
            <ListItemText
              sx={{ textAlign: 'right' }}
              primary={user.company}
              secondary={user.role}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => window.location.assign('/app/customers')}
        >
          {t('dashboard.viewAll')}
        </Button>
      </Box>
    </Card>
  );
};

LatestUsers.propTypes = {
  users: PropTypes.array.isRequired,
};

export default LatestUsers;
