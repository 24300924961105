import { Helmet } from 'react-helmet';
import React from 'react';
import { Box, colors } from '@material-ui/core';
import ProjectListResults from 'src/components/projects/ProjectListResults';
import auth from '../firebase';
import {statusses, factoryStatusses} from '../common/status.js';

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    // COMPANIES FOR SELECTION DEPENDING ON USER ROLE
    this.state = {
      projects: [], userData: {}, companies: [], mounted: false
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getProjectData();
      }
    });
  }

  getProjectData() {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    this.setState({ userData });

    if (userData.role === 'super_admin') {
      return auth.app.firestore()
        .collection('projects')
        .get()
        .then((snap) => {
          const projects = [];
          const promises = [];
          promises.push(auth.app.firestore()
            .collection('companies')
            .get()
            .then((snapshot) => {
              const newCompanies = [];
              snapshot.docs.forEach((document) => {
                newCompanies.push(document.id);
              });
              this.setState({ companies: newCompanies });
            }));

          snap.docs.forEach((doc) => {
            promises.push(auth.app.firestore()
              .collection('jobs')
              .where('project', '==', doc.id)
              .get()
              .then(async (p) => {
                const newOrders = [];
                p.docs.forEach((d) => {
                  newOrders.push({ ...d.data(), ...{ id: d.id } });
                });
                return this.getDataSet(newOrders);
              })
              .then((dataset) => {
                projects.push({ ...doc.data(), ...{ id: doc.id, createdDate: doc.data().created.seconds ? doc.data().created.toDate() : new Date(), dataset } });
              }));
          });

          return Promise.all(promises).then(() => {
            this.setState({ projects, mounted: true });
            auth.app.firestore().collection('analytics').doc('projects').update({ currentProjects: projects.length });
            return null;
          });
        });
    }
    this.setState({ companies: [userData.company] });
    return auth.app.firestore()
      .collection('projects')
      .where('customer', '==', userData.company)
      .get()
      .then((snap) => {
        const projects = [];
        const promises = [];
        snap.docs.forEach((doc) => {
          let query = auth.app.firestore().collection('jobs');
          query = query.where('project', '==', doc.id);
          if (userData.role === 'factory_user') {
            query = query.where('factoryStatus', '!=', null);
          }
          promises.push(query.get()
            .then(async (p) => {
              const newOrders = [];
              p.docs.forEach((d) => {
                newOrders.push({ ...d.data(), ...{ id: d.id } });
              });
              return this.getDataSet(newOrders);
            })
            .then((dataset) => {
              projects.push({ ...doc.data(), ...{ id: doc.id, createdDate: doc.data().created.seconds ? doc.data().created.toDate() : new Date(), dataset } });
            }));
        });

        return Promise.all(promises).then(() => {
          this.setState({ projects, mounted: true });
          return null;
        });
      });
  }

  getJobs(status, jobs, isFactory) {
    return jobs?.length > 0 ? jobs.filter((j) => isFactory ? j.factoryStatus === status : j.status === status).length : 0;
  }

  getDataSet(jobs) {
    const { userData } = this.state;
    const isFactory = userData.role === 'factory_user';
    // eslint-disable-next-line react/destructuring-assignment
    return {
      datasets: [
        {
          data: isFactory ? factoryStatusses.map((status) => this.getJobs(status, jobs, isFactory)) : statusses.map((status) => this.getJobs(status, jobs, isFactory)),
          backgroundColor: isFactory ? [
            colors.grey[500],
            colors.orange[800],
            colors.lightGreen[500],
          ] : [
            colors.grey[500],
            colors.yellow[700],
            colors.orange[500],
            colors.orange[800],
            colors.lightGreen[500],
            colors.red[600]
          ],
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      text: jobs.length,
      labels: isFactory ?
        factoryStatusses :
        statusses,
    };
  }

  setProjects(projects) {
    this.setState({
      projects
    });
  }

  render() {
    const {
      projects, userData, companies, mounted
    } = this.state;

    return (
      <>
        <Helmet>
          <title>Projects | Zetwerk</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          {mounted
          && <ProjectListResults refresh={() => this.getProjectData()} handler={(prop) => this.setProjects(prop)} projects={projects} userData={userData} companies={companies} />}
        </Box>
      </>
    );
  }
}

export default CustomerList;
