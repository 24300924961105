import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import { statusses, factoryStatusses} from '../../common/status.js';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const TrafficByDevice = ({ jobs, isFactory, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const getJobs = (status) => jobs?.length > 0 ? jobs.filter((j) => isFactory ? j.factoryStatus === status : j.status === status).length : 0;

  const data = {
    datasets: [
      {
        data: isFactory ? factoryStatusses.map((status) => getJobs(status)) : statusses.map((status) => getJobs(status)),
        backgroundColor: isFactory ? [
          colors.grey[500],
          colors.orange[800],
          colors.lightGreen[500],
        ] : [
          colors.grey[500],
          colors.yellow[700],
          colors.orange[500],
          colors.orange[800],
          colors.lightGreen[500],
          colors.red[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    text: jobs?.length,
    labels: (isFactory ?
      factoryStatusses :
      statusses).map((status) => t(`jobsAndProjects.${status}`)),
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };


  const devices = (isFactory ? factoryStatusses : statusses).map((status, index) => ({
    title: status,
    value: Math.round((data.datasets[0].data[index] / jobs.length) * 100) || 0,
    color: data.datasets[0].backgroundColor[index],
  }));


  return (
    <Card {...props}>
      <CardHeader title={t('dashboard.jobsStatus')} />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center',
              }}
            >
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {t(`jobsAndProjects.${title}`)}
              </Typography>
              <Typography
                style={{ color, paddingLeft: 10 }}
                variant="h3"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

TrafficByDevice.propTypes = {
  jobs: PropTypes.array.isRequired,
  isFactory: PropTypes.bool.isRequired,
};

export default TrafficByDevice;
