import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PropTypes from 'prop-types';
import {
  Trello as ProjectsIcon,
} from 'react-feather';
import { red, green, orange } from '@material-ui/core/colors';

const TotalProjects = ({ currentProjects, previousMonth, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  let procent = 0;
  let positive = true;
  let noDifference = true;

  if (previousMonth < currentProjects) {
    positive = true;
    noDifference = false;
    procent = (currentProjects / previousMonth) * 100;
  } else if (previousMonth > currentProjects) {
    positive = false;
    noDifference = false;
    procent = 100 - (currentProjects / previousMonth) * 100;
  } else {
    noDifference = true;
  }

  return (
    <Card
      sx={{ height: '100%' }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {t('dashboard.totalProjects')}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {currentProjects}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: orange[600],
                height: 56,
                width: 56
              }}
            >
              <ProjectsIcon />
            </Avatar>
          </Grid>
        </Grid>
        { !noDifference && previousMonth !== 0
        && (
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {positive
            ? <ArrowUpwardIcon sx={{ color: green[900] }} /> : <ArrowDownwardIcon sx={{ color: red[900] }} />}
          <Typography
            sx={{
              color: positive ? green[900] : red[900],
              mr: 1
            }}
            variant="body2"
          >
            {procent}
            %
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {t('dashboard.lastMonth')}
          </Typography>
        </Box>
        )}
      </CardContent>
    </Card>
  );
};

TotalProjects.propTypes = {
  currentProjects: PropTypes.number.isRequired,
  previousMonth: PropTypes.number.isRequired,
};

export default TotalProjects;
