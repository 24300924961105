import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import {
  Tool as ToolIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { red } from '@material-ui/core/colors';

const TotalJobs = ({ currentJobs, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <Card
      sx={{ height: '100%' }}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {t('dashboard.totalJobs')}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {currentJobs}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: red[600],
                height: 56,
                width: 56
              }}
            >
              <ToolIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalJobs.propTypes = {
  currentJobs: PropTypes.number.isRequired,
};

export default TotalJobs;
