import { Helmet } from 'react-helmet';
import React from 'react';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import CustomerListResults from 'src/components/customer/CustomerListResults';
import auth from '../firebase';

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { customers: [], companies: [], currentUser: {} };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
        this.getCurrentUser();
        this.getCompanies();
      }
    });
  }

  getCurrentUser() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const currentUser = userSnap.data();
        this.setState({ currentUser });
      });
  }

  getCompanies() {
    const companies = [];
    const userData = JSON.parse(sessionStorage.getItem('user'));
    if (userData.role === 'super_admin') {
      auth.app.firestore()
        .collection('companies')
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            companies.push(doc.id);
          });
          this.setState({ companies });
        });
    } else {
      this.setState({ companies: [userData.company] });
    }
  }

  getUserData() {
    const promises = [];
    promises.push(this.getUsers());
    const usersList = auth.app.functions().httpsCallable('getuserlist');
    promises.push(usersList());
    Promise.all(promises).then(([, authData]) => {
      setTimeout(() => {
        const { customers } = this.state;
        const currentUserData = JSON.parse(JSON.stringify(customers));
        const authPersons = authData.data;
        currentUserData.forEach((person, index) => {
          const userEntry = authPersons.find((p) => p.uid === person.id);
          if (userEntry) {
            currentUserData[index] = { ...person, ...userEntry };
          }
        });
        this.setState({ customers: currentUserData });
      }, 100);
    }).catch(() => null);
  }

  setCustomers(customers) {
    this.setState({
      customers
    });
  }

  getUsers() {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    if (userData.role === 'super_admin') {
      return auth.app.firestore()
        .collection('users')
        .get()
        .then((snap) => {
          const users = [];
          const promises = [];
          snap.docs.forEach((doc) => {
            if (doc.data().avatar) {
              promises.push(auth.app.storage().ref(doc.data().avatar).getDownloadURL().then((url) => {
                users.push({ ...doc.data(), ...{ id: doc.id, avatarUrl: url } });
              })
                .catch(() => users.push({ ...doc.data(), ...{ id: doc.id } })));
            } else {
              users.push({ ...doc.data(), ...{ id: doc.id } });
            }
          });
          return Promise.all(promises).then(() => {
            this.setState({ customers: users });
            auth.app.firestore().collection('analytics').doc('users').update({ currentUsers: users.length });
          });
        });
    }
    return auth.app.firestore()
      .collection('users')
      .where('company', '==', userData.company)
      .get()
      .then((snap) => {
        const users = [];
        const promises = [];
        snap.docs.forEach((doc) => {
          if (doc.data().avatar) {
            promises.push(auth.app.storage().ref(doc.data().avatar).getDownloadURL().then((url) => {
              users.push({ ...doc.data(), ...{ id: doc.id, avatarUrl: url } });
            })
              .catch(() => users.push({ ...doc.data(), ...{ id: doc.id } })));
          } else {
            users.push({ ...doc.data(), ...{ id: doc.id } });
          }
        });
        return Promise.all(promises).then(() => {
          this.setState({ customers: users });
        });
      });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const { customers, companies, currentUser } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {t('common.users')}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <CustomerListResults refresh={() => this.getUserData()} handler={(prop) => this.setCustomers(prop)} customers={customers} companies={companies} currentUser={currentUser} />
        </Box>
      </>
    );
  }
}

export default withTranslation()(CustomerList);
