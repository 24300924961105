import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  colors,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const LatestOrders = ({ currentJobs, isFactory, archived, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const orders = currentJobs.filter((job) => job.ordered && (archived ? job.factoryStatus === 'pickedUp' : job.factoryStatus !== 'pickedUp')).sort((a, b) => b.created.toDate() - a.created.toDate());
  const renderRow = (order) => {
    if (isFactory) {
      const label = t(`jobsAndProjects.${order.factoryStatus}`);
      return (<TableCell>
        {order.factoryStatus === 'ready'
          ? (
            <Chip
            style={{backgroundColor: colors.lightGreen[600], color: 'white'}}
              label={label}
              size="small"
            />
          )
          : order.factoryStatus === 'inProgress' ? (
            <Chip
              color="primary"
              label={label}
              size="small"
            />
          ) : (
            <Chip
              color="default"
              label={label}
              size="small"
            />
          )}
      </TableCell>);
    } else {
      const label = t(`jobsAndProjects.${order.status}`);
      return (<TableCell>
          <Chip
            style={{
              color: 'white',
              backgroundColor: order.status === 'completed'
              ? '#bfc839'
              : order.status === 'waitingForApproval'
              ? '#ffc211'
              : order.status === 'approved'
              ? '#f1a900'
              : order.status === 'inFactory'
              ? '#de6216'
              : order.status === 'rejected'
              ? '#ff0000'
              : 'grey'
            }}
            label={label}
            size="small"
          />
      </TableCell>);
    }
  };
  return (
    <Card {...rest} sx={{ height: '100%' }}>
      <CardHeader title={archived ? t('dashboard.archived') : t('dashboard.latestOrders')} />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('jobsAndProjects.projectName')}
                </TableCell>
                <TableCell>
                  {t('jobsAndProjects.projectNumber')}
                </TableCell>
                <TableCell>
                  {t('jobsAndProjects.jobName')}
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel>
                      {t('jobsAndProjects.date')}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {t('jobsAndProjects.status')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.slice(0, 6).map((order) => (
                <TableRow
                  hover
                  key={order.id}
                  onClick={() => window.location.assign(`/app/job/${order.id}`)}
                >
                  <TableCell>
                    {order.projectName}
                  </TableCell>
                  <TableCell>
                    {order.project}
                  </TableCell>
                  <TableCell>
                    {order.id}
                  </TableCell>
                  <TableCell>
                    {moment(order.created.toDate()).format('DD/MM/YYYY')}
                  </TableCell>
                  {renderRow(order)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          {t('dashboard.viewAll')}
        </Button>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  currentJobs: PropTypes.array.isRequired,
  isFactory:  PropTypes.bool.isRequired,
  archived:  PropTypes.bool,
};

export default LatestOrders;
