import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Avatar,
  Dialog,
  Box,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import DownloadIcon from '@material-ui/icons/Download';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachMoneyIcon from '@material-ui/icons/Euro';
import { indigo } from '@material-ui/core/colors';
import InjectedCheckoutForm from '../components/payment/StripeComponent'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from "@stripe/react-stripe-js";
import moment from 'moment';
import auth from '../firebase';
import InjectedSubscriptionForm from 'src/components/payment/StripeSubscriptionComponent';

const stripe = loadStripe(
  "pk_live_51NgoFaB3Dv61KYkQFGv1kNXrbUePKO2INpKBdYfIiYVUcJn1mhOz7ErF4MbP6z8d29saxKBLhAqE1bBhfRtryJMe00ZAK7wzhK"
);

class PlanAndPayment extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      userData: {},
      jobs: [],
      jobId: '',
      pdfUrl: '',
      numPages: null,
      pageNumber: 1,
      screenHeight: 0,
      company: null,
      users: [],
      config: {},
      userCost: 0,
      jobCost: 0,
      jobsOrdered: [],
      jobsOrderedPrevious: [],
      usersPrevious: [],
      companies: [],
      selectedCompany: '',
      configureModal: false,
      jobCostModal: false,
      setCost: 0,
      setWeight: 0,
      setWeightCostColored: 0,
      setWeightCostNoColor: 0,
      setConnectorCost: 0,
      subscriptions: [],
      selectedPlan: '',
      lastInvoiceDocument: null,
      clientSecret: '',
      paymentScreen: false,
      paymentModal: false,
      subscribeMode: false,
      oneTimePayment: false,
    };
  }

  componentDidMount() {
    this.setState({ screenHeight: window.innerHeight });
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
        this.getPlanConfig();
        this.getSubscriptions();
      }
    });
    
  }

  getPlanConfig() {
    auth.app.firestore().collection('plan').doc('config').get()
      .then((conf) => {
        this.setState({ config: conf.data(), setCost: conf.data().userCost });
      });
  }

  async savePayment(paymentToken, lastInvoiceDocument) {
    const stripeF = await stripe;
    if (!paymentToken) return;
    const {paymentIntent} = await stripeF.retrievePaymentIntent(paymentToken);
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      await auth.app.firestore().collection('invoices').doc(lastInvoiceDocument.id).update({paid: true});
      lastInvoiceDocument.paid = true;
      this.setState({lastInvoiceDocument});
    } else {
      // Handle unsuccessful, processing, or canceled payments and API errors here
    }
  }

  getSubscriptions() {
    auth.app.firestore()
      .collection('plan')
      .doc('subscriptions')
      .collection('subscriptions')
      .get()
      .then((subs) => {
        const subscriptions = subs.docs.map(doc => {
          return { ...doc.data(), ...{ id: doc.id } };
        });
        this.setState({ subscriptions });
      });
  }

  getJobCostConfig() {
    auth.app.firestore().collection('calculations').doc('config').get()
      .then((conf) => {
        this.setState({
          setConnectorCost: conf.data().connectorCost,
          setWeight: conf.data().weight,
          setWeightCostColored: conf.data().weightCostColored,
          setWeightCostNoColor: conf.data().weightCostNoColor,
        });
      });
  }

  async setUserCost() {
    for (let i = 0; i < this.state.subscriptions.length; i++) {
      await auth.app.firestore()
      .collection('plan')
      .doc('subscriptions')
      .collection('subscriptions')
      .doc(this.state.subscriptions[i].id)
      .update({
        cost: this.state.subscriptions[i].cost
      });
    }

    this.getUserData();
    this.setState({ configureModal: false });
  }

  setJobCost() {
    const {
      setConnectorCost, setWeight, setWeightCostNoColor, setWeightCostColored, company,
    } = this.state;
    auth.app.firestore().collection('companies').doc(company.id).update({
      jobCostConfig: {
        connectorCost: parseFloat(setConnectorCost),
        weight: parseFloat(setWeight),
        weightCostColored: parseFloat(setWeightCostColored),
        weightCostNoColor: parseFloat(setWeightCostNoColor),
      }
    })
      .then(() => {
        this.setState({ jobCostModal: false });
      });
  }

  setSubscription(subscription) {
    const { company } = this.state;
    auth.app.firestore().collection('companies').doc(company.id).update({
      subscription,
    });

    this.setState({ selectedPlan: subscription });
  }

  async getUserData() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.setState({ userData: user });
    if (user.role === 'company_admin') {
      this.getCompanyData(user);
    } else if (user.role === 'super_admin') {
      this.getCompanies(user);
    }
  }

  getCompanies(user) {
    auth.app.firestore()
      .collection('companies')
      .get()
      .then((snap) => {
        const companies = [];
        snap.docs.forEach((doc) => {
          companies.push({ ...doc.data(), ...{ id: doc.id } });
        });
        companies.push({ id: '', name: '' });
        this.setState({ companies });
        this.getCompanyData(user);
        this.setState({ selectedCompany: user.company });
      });
  }

  getJobData(user, company) {
    if (user.company) {
      auth.app.firestore()
        .collection('jobs')
        .where('company', '==', user.company)
        .get()
        .then((snap) => {
          const jobs = [];
          snap.docs.forEach((doc) => {
            jobs.push({ ...doc.data(), ...{ id: doc.id } });
          });
          this.setState({ jobs });
          this.calculateJobCost(jobs, company);
        });
    }
  }

  getCompanyData(user) {
    auth.app.firestore()
      .collection('companies')
      .doc(user.company)
      .get()
      .then((snap) => {
        this.setState({ company: { ...snap.data(), ...{ id: snap.id } }, selectedPlan: snap.data()?.subscription || '' });
        if (snap.data() && snap.data().jobCostConfig) {
          this.setState({
            setConnectorCost: snap.data().jobCostConfig.connectorCost,
            setWeight: snap.data().jobCostConfig.weight,
            setWeightCostColored: snap.data().jobCostConfig.weightCostColored,
            setWeightCostNoColor: snap.data().jobCostConfig.weightCostNoColor,
          });
        } else {
          this.getJobCostConfig();
        }
        this.getJobData(user, snap.data());
        this.getUsers(user, snap.data());
        this.getLastInvoice(user.company);
      });
  }

  async getLastInvoice(company) {
    if (company) {
      const querySnapshot = await auth.app.firestore()
      .collection('invoices')
      .where('company', '==', company)
      .orderBy('created', 'desc') // Order by 'created' attribute in descending order
      .limit(1)                   // Limit the result to 1 document
      .get();

      if (!querySnapshot.empty) {
        const lastInvoiceDocument = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
        const queryParameters = new URLSearchParams(window.location.search);
        const paymentToken = queryParameters.get("payment_intent_client_secret");
        this.savePayment(paymentToken, lastInvoiceDocument);
        this.setState({ lastInvoiceDocument })
      }
    }
  }

  getUsers(user, company) {
    if (user.company) {
      auth.app.firestore()
        .collection('users')
        .where('company', '==', user.company)
        .get()
        .then((snap) => {
          const users = [];
          snap.docs.forEach((doc) => {
            users.push({ ...doc.data(), ...{ id: doc.id } });
          });
          this.setState({ users });
          this.calculateUserCost(users, company);
        });
    }
  }

  changeCompany(event) {
    const id = event.target.value;
    if (id.length > 0) {
      this.getCompanyData({ company: id });
      this.setState({ selectedCompany: id });
    }
  }

  setManualPayment(event) {
    const value = event.target.checked;
    const { company } = this.state;
    auth.app.firestore().collection('companies').doc(company.id).update({
      manualPayment: value,
    });

    company.manualPayment = value;
    this.setState({ company });
  }

  calculateUserCost(users, company) {
    const { config } = this.state;
    const usersPrevious = [];
    let userCost = 0;
    users.forEach((usr) => {
      if (company.lastInvoice) {
        const begin = moment(company.lastInvoice.toDate()).subtract(1, 'month');
        const end = moment(company.lastInvoice.toDate());
        const userCreated = moment(usr.created.toDate());
        if (userCreated.isBefore(begin)) {
          userCost += config.userCost;
          usersPrevious.push(usr);
        } else if ((userCreated.isAfter(begin) || userCreated.isSame(begin)) && (userCreated.isBefore(end) || userCreated.isSame(end))) {
          userCost += config.userCost * (moment.duration(end.diff(userCreated)).asDays() / moment.duration(end.diff(begin)).asDays());
          usersPrevious.push(usr);
        }
      }
    });

    this.setState(({ userCost: Math.round(userCost * 100) / 100, usersPrevious }));
  }

  calculateJobCost(jobs, company) {
    let jobCost = 0;
    const jobsOrdered = [];
    const jobsOrderedPrevious = [];
    jobs.forEach((job) => {
      if (job.status === 'Done') {
        const begin = moment(company.lastInvoice.toDate()).subtract(1, 'month');
        const end = moment(company.lastInvoice.toDate());
        const jobCompleted = moment(job.jobCompleted.toDate());
        if ((jobCompleted.isAfter(begin) || jobCompleted.isSame(begin)) && (jobCompleted.isBefore(end) || jobCompleted.isSame(end))) {
          jobCost += job.cost;
          jobsOrderedPrevious.push(job);
        } else if (jobCompleted.isAfter(end)) {
          jobsOrdered.push(job);
        }
      }
    });

    this.setState(({ jobCost: Math.round(jobCost * 100) / 100, jobsOrdered, jobsOrderedPrevious }));
  }

  downloadFile(file) {
    auth.app.storage().ref(file).getDownloadURL().then((url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    });
  }

  async getClientSecret() {
    const { lastInvoiceDocument } = this.state;
    if (lastInvoiceDocument.totalInclTax) {
      const clientSecretCall = auth.app.functions().httpsCallable('getClientSecret');
      const clientSecret = await clientSecretCall({ amount: parseInt(lastInvoiceDocument.totalInclTax * 100, 10) })
        .then((result) => {
          return result.data?.clientSecret;
        }).catch((err) => {
          this.setState({
            loadingSubmit: false,
          })
        });

      if (clientSecret) {
        this.setState({
          clientSecret,
        });
    
        setTimeout(() => this.setState({
          oneTimePayment: true,
        }), 100);
      }
    }
  }

  async unsubscribePayment() {
    const { company } = this.state;
    const unsubscribeCall = auth.app.functions().httpsCallable('cancelSubscription');
    await  unsubscribeCall({ subscriptionId: company.subscriptionId, companyId: company.id});
    window.location.reload();
  }


  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      jobId,
      selectedPlan,
      lastInvoiceDocument,
      subscriptions, setConnectorCost,
      setWeightCostNoColor,
      setWeightCostColored,
      setWeight,
      jobsOrdered,
      jobCostModal,
      jobsOrderedPrevious,
      userData,
      company,
      users,
      userCost,
      jobCost,
      usersPrevious,
      companies,
      selectedCompany,
      configureModal,
      setCost,
      paymentModal,
      clientSecret,
      subscribeMode,
      oneTimePayment
    } = this.state;

    const plan = subscriptions?.find((s) => s.id === selectedPlan);

    return (
      <>
        <Helmet>
          <title>
            {t('planAndPayment.planAndPayment')}
            {' '}
            {jobId}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              sx={{ py: isMobile ? 0 : 3, px: isMobile ? 0 : 3 }}
              spacing={!isMobile ? 3 : 0}
            >
              <Grid
                container
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                sx={{ px: isMobile ? 0 : 3, marginBottom: 3 }}
              >
                <Grid
                  item
                  lg={3}
                  sm={3}
                  xl={3}
                  xs={12}
                  sx={{ paddingRight: isMobile ? 0 : 4.5, marginBottom: isMobile ? 3 : 0 }}
                >
                  {userData.role === 'super_admin'
                    && (
                      <TextField
                        fullWidth
                        name="role"
                        required
                        value={selectedCompany}
                        onChange={(id) => this.changeCompany(id)}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                      >
                        {companies.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                          >
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    )}
                </Grid>
                <Grid
                  item
                  lg={9}
                  sm={9}
                  xl={9}
                  xs={12}
                  alignSelf={isMobile ? null : 'flex-end'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: isMobile ? '100%' : null,
                      justifyContent: isMobile ? null : 'flex-end'
                    }}
                  >
                    {userData.role === 'company_admin'
                      && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: isMobile ? 0 : 12 }}
                          onClick={() => this.setState({ jobCostModal: true })}
                        >
                          {t('planAndPayment.configureJobCost')}
                        </Button>
                      )}
                    {userData.role === 'super_admin'
                      && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: isMobile ? 0 : 12 }}
                          onClick={() => this.setState({ configureModal: true })}
                        >
                          {t('planAndPayment.configureUserCost')}
                        </Button>
                      )}
                  </Box>
                </Grid>
              </Grid>
              {company && (
                <Grid
                  container
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                  spacing={!isMobile ? 3 : 0}
                  sx={{ px: isMobile ? 0 : 3, marginBottom: 3, height: '50%' }}
                >
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                    sx={{ marginBottom: isMobile ? 3 : 0 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              {t('planAndPayment.costPrevious')}
                            </Typography>
                            <Typography
                              color="textPrimary"
                              variant="h3"
                            >
                              €
                              {' '}
                              {Math.round((lastInvoiceDocument ? lastInvoiceDocument.userCost : 0) + jobCost)}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                backgroundColor: indigo[600],
                                height: 56,
                                width: 56
                              }}
                            >
                              <AttachMoneyIcon />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                    sx={{ marginBottom: isMobile ? 3 : 0 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          sx={{ justifyContent: 'space-between', height: 56 }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              {t('planAndPayment.previous')}
                            </Typography>
                            <Typography
                              color="textPrimary"
                              variant="h4"
                            >
                              {company.lastInvoice ? `${moment(company.lastInvoice.toDate()).subtract(1, 'month').format('DD/MM/YYYY')} - ${moment(company.lastInvoice.toDate()).format('DD/MM/YYYY')}` : '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                    sx={{ marginBottom: isMobile ? 3 : 0 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              {t('planAndPayment.costJobs')}
                            </Typography>
                            <Typography
                              color="textPrimary"
                              variant="h3"
                            >
                              {jobsOrderedPrevious.length}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{ alignSelf: 'flex-end' }}
                          >
                            <Typography
                              color="textPrimary"
                              variant="h3"
                            >
                              €
                              {' '}
                              {jobCost}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                    sx={{ marginBottom: isMobile ? 3 : 0 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              {t('planAndPayment.costUsers')}
                            </Typography>
                            <Typography
                              color="textPrimary"
                              variant="h3"
                            >
                              {usersPrevious.length}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{ alignSelf: 'flex-end' }}
                          >
                            <Typography
                              color="textPrimary"
                              variant="h3"
                            >
                              €
                              {' '}
                              {plan ? plan.cost * users.length : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  {company && (
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      sx={{ marginBottom: isMobile ? 3 : 0 }}
                    >
                      <Card>
                        <CardContent>
                          <Grid
                            container
                            sx={{ justifyContent: 'space-between' }}
                          >
                            <Grid item>
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h6"
                              >
                                {t('planAndPayment.selectedPlan').toUpperCase()}
                              </Typography>
                              {userData.role === 'super_admin' ? (<TextField
                                fullWidth
                                name="plan"
                                required
                                value={selectedPlan}
                                onChange={(event) => this.setSubscription(event.target.value)}
                                select
                                sx={{ width: 200 }}
                                SelectProps={{ native: true }}
                                variant="outlined"
                              >
                                {subscriptions.map((option) => (
                                  <option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.name}
                                  </option>
                                )).concat([<option
                                  key={''}
                                  value={''}
                                >
                                  No Selected
                                </option>])}
                              </TextField>) : (
                                <Typography
                                  color="textPrimary"
                                  variant="h3"
                                >
                                  {company.subscription?.toUpperCase() || 'No plan selected'}
                                </Typography>)}
                            </Grid>
                            <Grid
                              item
                              sx={{ alignSelf: 'flex-end' }}
                            >
                              <Typography
                                color="textPrimary"
                                variant="h6"
                              >
                                {t('planAndPayment.contactSupport')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>)}
                  {lastInvoiceDocument && (<Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    sx={{ marginBottom: isMobile ? 3 : 0 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <Grid item>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                            >
                              {t('planAndPayment.duePayment').toUpperCase()}
                            </Typography>
                            <Typography
                              color="textPrimary"
                              variant="h3"
                            >
                              Due: {moment(lastInvoiceDocument.due.toDate()).format('DD/MM/YYYY')}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{ alignSelf: 'flex-end' }}
                          >
                            {lastInvoiceDocument.paid || company.subscriptionId ?
                              (<Typography
                                color="textPrimary"
                                variant="h3"
                              >
                                {t('planAndPayment.paid')}
                              </Typography>) : (<Button
                                color="primary"
                                variant="contained"
                                sx={{ width: 100 }}
                                onClick={() => this.setState({paymentModal: true})}
                              >
                                {t('planAndPayment.pay')}
                              </Button>)}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>)}
                  {selectedCompany && company && userData.role === 'super_admin' && (
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      sx={{ marginBottom: isMobile ? 3 : 0 }}
                    >
                      <Card>
                        <CardContent>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"  // Vertically align items
                          >
                            <Grid item>
                              <Typography color="textPrimary" variant="h4">
                                Manual Payments
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sx={{ marginLeft: 'auto' }}  // Align the Checkbox to the right
                            >
                              <Checkbox
                                checked={company.manualPayment || false}
                                onChange={(event) => this.setManualPayment(event)}
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#EE7022',
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {company && company.subscriptionId && (<Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    sx={{ marginBottom: isMobile ? 3 : 0 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <Grid item>
                            <Typography
                              color="textPrimary"
                              variant="h5"
                            >
                              Your company subscribed for automatic payment
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{ alignSelf: 'flex-end' }}
                          >
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{ width: 150 }}
                                onClick={() => this.unsubscribePayment()}
                              >
                                UNSUBSCRIBE
                              </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
              )}
                </Grid>
              )}
              {company && (
                <Grid
                  container
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    sx={{ marginBottom: 3 }}
                  >
                    <Card sx={{ height: '100%' }}>
                      <CardHeader
                        title="Details"
                      />
                      <Divider />
                      <PerfectScrollbar>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {company.name && (
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('common.companyName')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {company.name}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('users.registrationDate')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {company.created ? moment(company.created.toDate()).format('DD/MM/YYYY') : ''}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('planAndPayment.currentPeriod')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {!company.lastInvoice ? `${moment(company.created.toDate()).format('DD/MM/YYYY')} - ${moment(company.created.toDate()).add(1, 'month').format('DD/MM/YYYY')}` : `${moment(company.lastInvoice.toDate()).format('DD/MM/YYYY')} - ${moment(company.lastInvoice.toDate()).add(1, 'months').format('DD/MM/YYYY')}`}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('planAndPayment.currentUsers')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {users.length}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('planAndPayment.orderedjobs')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {jobsOrdered.length}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          )}
                        </Box>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      height={isMobile ? '50vh' : '40vh'}
                    >
                      <Card sx={{ height: '100%', paddingBottom: 9 }}>
                        <CardHeader
                          title={t('planAndPayment.invoices')}
                        />
                        <Divider />
                        <PerfectScrollbar>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <Table>
                              <TableBody>
                                {company.invoices && company.invoices.map((file) => (
                                  <TableRow
                                    hover
                                    key={file}
                                    selected={users.indexOf(file) !== -1}
                                  >
                                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => this.downloadFile(file)}>
                                      <Box
                                        sx={{
                                          alignItems: 'center',
                                          display: 'flex',
                                        }}
                                      >
                                        <Typography
                                          color="textPrimary"
                                          variant="body2"
                                        >
                                          {file}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'right', paddingRight: 2.5 }}>
                                      <IconButton sx={{ color: 'black', height: 35, width: 35 }}>
                                        <DownloadIcon onClick={() => this.downloadFile(file)} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </PerfectScrollbar>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Container>
          <Dialog
            onBackdropClick={() => this.setState({ configureModal: false })}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 20,
              minWidth: '100vw',
              justifyContent: 'center'
            }}
            open={configureModal}
          >
            <Grid
              container
              width={isMobile ? '90vw' : '100%'}
              spacing={0}
              sx={{ backgroundColor: 'white' }}
            >
              <Card sx={{ width: '30vw', paddingRight: 2, paddingLeft: 2 }}>
                <CardHeader
                  titleTypographyProps={{ fontSize: 20, marginBottom: 2 }}
                  title={t('planAndPayment.paymentSettings')}
                />
                {subscriptions.map((s, i) => (
                <TextField
                  sx={{marginBottom: 3}}
                  fullWidth
                  label={s.name}
                  name="userCost"
                  value={s.cost}
                  type="number"
                  onChange={(e) => {
                    const {subscriptions} = this.state;
                    subscriptions[i].cost = e.target.value;
                    this.setState({ subscriptions })}}
                  required
                  variant="outlined"
                />))}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    style={{ backgroundColor: 'lightgrey', color: 'black' }}
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    onClick={() => this.setState({ configureModal: false })}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.setUserCost()}
                  >
                    {t('common.save')}
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Dialog>
          <Dialog
            onBackdropClick={() => this.setState({ jobCostModal: false })}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 20,
              minWidth: '100vw',
              justifyContent: 'center'
            }}
            open={jobCostModal}
          >
            <Grid
              container
              width={isMobile ? '90vw' : '100%'}
              spacing={0}
              sx={{ backgroundColor: 'white' }}
            >
              <Card sx={{ width: '30vw', paddingRight: 2, paddingLeft: 2 }}>
                <CardHeader
                  titleTypographyProps={{ fontSize: 20, marginBottom: 2 }}
                  title={t('planAndPayment.jobCostSettings')}
                />
                <TextField
                  fullWidth
                  label={t('planAndPayment.connectorCost')}
                  name="connectorCost"
                  value={setConnectorCost}
                  sx={{ marginBottom: 3 }}
                  type="number"
                  onChange={(e) => this.setState({ setConnectorCost: e.target.value })}
                  required
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label={t('planAndPayment.weightMM')}
                  name="weight"
                  value={setWeight}
                  sx={{ marginBottom: 3 }}
                  type="number"
                  onChange={(e) => this.setState({ setWeight: e.target.value })}
                  required
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label={`${t('planAndPayment.costPerKg')}${t('planAndPayment.colored')}`}
                  name="weightCostColored"
                  value={setWeightCostColored}
                  sx={{ marginBottom: 3 }}
                  type="number"
                  onChange={(e) => this.setState({ setWeightCostColored: e.target.value })}
                  required
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label={t('planAndPayment.costPerKg')}
                  name="weightCostNoColor"
                  sx={{ marginBottom: 3 }}
                  value={setWeightCostNoColor}
                  type="number"
                  onChange={(e) => this.setState({ setWeightCostNoColor: e.target.value })}
                  required
                  variant="outlined"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    style={{ backgroundColor: 'lightgrey', color: 'black' }}
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    onClick={() => this.setState({ jobCostModal: false })}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.setJobCost()}
                  >
                    {t('common.save')}
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Dialog>
          <Dialog
            onBackdropClick={() => this.setState({ paymentModal: false })}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 20,
              minWidth: '100vw',
              justifyContent: 'center'
            }}
            open={paymentModal}
          >
            <Grid
              container
              width={isMobile ? '90vw' : '100%'}
              spacing={0}
              sx={{ backgroundColor: 'white' }}
            >
              { lastInvoiceDocument && (<Card sx={{ width: '30vw', paddingRight: 2, paddingLeft: 2 }}>
                <Box
                  sx={{
                    my: 2,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box onClick={() => this.setState({ paymentModal: false })} sx={{ width: '100%', display: 'flex', marginBottom: 5, cursor: 'pointer' }}>
                    <ArrowBackIosIcon />
                    <Typography>
                      Back
                    </Typography>
                  </Box>
                  {oneTimePayment ?
                  <Elements
                    stripe={stripe}
                    options={{
                      clientSecret: clientSecret,
                      appearance: { theme: "stripe", variables : {
                        colorPrimary: '#EE7022',
                        colorPrimaryText: '#EE7022',
                      } },
                    }}
                  >
                    <InjectedCheckoutForm lastInvoiceDocument={lastInvoiceDocument} />
                  </Elements> :
                  subscribeMode ? <Elements
                    stripe={stripe}
                    options={{
                      mode: 'subscription',
                      currency: 'eur',
                      amount: lastInvoiceDocument.users?.length >= plan.minimum ? lastInvoiceDocument.users?.length : plan.minimum,
                      appearance: { theme: "stripe", variables : {
                        colorPrimary: '#EE7022',
                        colorPrimaryText: '#EE7022',
                      } },
                    }}
                  >
                    <InjectedSubscriptionForm lastInvoiceDocument={lastInvoiceDocument} plan={plan} userEmail={userData.email} companyId={company.id} />
                  </Elements>:
                  <div style={{ width: '100%'}}>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                      Do you want to subscribe for an automatic payment per month or do only for this time?
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width:  '100%' }} marginTop={5} marginBottom={2}>
                    
                    <Button
                    style={{ backgroundColor: 'lightgrey', color: 'black', width: '30%', marginRight: '20%' }}
                    variant="contained"
                    onClick={() => this.getClientSecret()}
                  >
                    Pay once
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{width: '30%', marginLeft: '20%'}}
                    onClick={() => this.setState({subscribeMode: true})}
                  >
                    Subscribe
                  </Button>
                    </Box>
                  </div>
                  }
                </Box>
              </Card>)}
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}

export default withTranslation()(PlanAndPayment);
