import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Clear';
import auth from '../../firebase';

const JobList = ({
  orders, isFactory, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  function deleteJob(job) {
    const newOrders = orders.filter((o) => o.id !== job.id);
    auth.app.firestore().collection('projects').doc(job.project).update({ jobs: newOrders.map((o) => o.id) });
    auth.app.firestore().collection('jobs').doc(job.id).delete()
      .then(() => rest.refresh());
  }

  const renderStatus = (order) => {
    if (isFactory) {
      const label = t(`jobsAndProjects.${order.factoryStatus}`);
      return (<TableCell>
        {order.factoryStatus === 'ready'
          ? (
            <Chip
              color="secondary"
              label={label}
              size="small"
            />
          )
          : order.factoryStatus === 'inProgress' ? (
            <Chip
              color="primary"
              label={label}
              size="small"
            />
          ) : (
            <Chip
              color="default"
              label={label}
              size="small"
            />
          )}
      </TableCell>);
    } else {
      const label = t(`jobsAndProjects.${order.status}`);
      return (<TableCell>
            <Chip
              style={{
                color: 'white',
                backgroundColor: order.status === 'completed'
                ? '#bfc839'
                : order.status === 'waitingForApproval'
                ? '#ffc211'
                : order.status === 'approved'
                ? '#f1a900'
                : order.status === 'inFactory'
                ? '#de6216'
                : order.status === 'rejected'
                ? '#ff0000'
                : 'grey'
              }}
              label={label}
              size="small"
            />
      </TableCell>);
    }
  };

  return (
    <Card sx={{ height: '100%', paddingBottom: 7 }}>
      <CardHeader title={t('jobsAndProjects.jobs')} />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 200 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('jobsAndProjects.projectName')}
                </TableCell>
                <TableCell>
                  {t('jobsAndProjects.date')}
                </TableCell>
                <TableCell>
                  {t('jobsAndProjects.status')}
                </TableCell>
                {!isFactory && <TableCell sx={{ stextAlign: 'center' }}>
                  {t('common.delete')}
                </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  sx={{ cursor: 'pointer' }}
                  onClick={() => window.location.assign(`/app/job/${order.id}`)}
                  hover
                  key={order.id}
                >
                  <TableCell>
                    {order.id}
                  </TableCell>
                  <TableCell>
                    {moment(order.created.toDate()).format('DD/MM/YYYY')}
                  </TableCell>
                  {renderStatus(order)}
                  {!isFactory && <TableCell sx={{ textAlign: 'center' }}>
                    <IconButton onClick={() => deleteJob(order)} sx={{ color: 'black', height: 35, width: 35 }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

JobList.propTypes = {
  orders: PropTypes.array.isRequired,
  isFactory:  PropTypes.bool.isRequired,
};

export default JobList;
