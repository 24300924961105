import { Helmet } from 'react-helmet';
import React from 'react';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import CompanyListResults from 'src/components/company/CompanyListResults';
import auth from '../firebase';

class CompanyList extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    // COMPANIES FOR SELECTION DEPENDING ON USER ROLE
    this.state = { userData: {}, companies: [] };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getCompanyData();
      }
    });
  }

  getCompanyData() {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    this.setState({ userData });

    return auth.app.firestore()
      .collection('companies')
      .get()
      .then((snap) => {
        const companies = [];
        snap.docs.forEach((doc) => {
          companies.push({ ...doc.data(), ...{ id: doc.id, createdDate: doc.data().created.seconds ? doc.data().created.toDate() : new Date() } });
        });
        this.setState({ companies });
        auth.app.firestore().collection('analytics').doc('companies').update({ currentCompanies: companies.length });
      });
  }

  setCompanies(companies) {
    this.setState({
      companies
    });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const { userData, companies } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {t('common.companies')}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <CompanyListResults refresh={() => this.getCompanyData()} handler={(prop) => this.setCompanies(prop)} companies={companies} userData={userData} />
        </Box>
      </>
    );
  }
}

export default withTranslation()(CompanyList);
