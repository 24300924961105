import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import CompanyProfile from 'src/components/company/CompanyProfile';
import CompanyProfileDetails from 'src/components/company/CompanyProfileDetails';
import auth from '../firebase';

class Account extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      companyData: {}, loaded: false, saving: false, companyId: ''
    };
  }

  componentDidMount() {
    const splitted = window.location.pathname.split('company/');
    this.setState({ companyId: decodeURI(splitted[1]) });
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getCompanyData(decodeURI(splitted[1]));
      }
    });
  }

  async getCompanyData(company) {
    auth.app.firestore()
      .collection('companies')
      .doc(company)
      .get()
      .then((compSnap) => {
        const companyData = { ...compSnap.data(), ...{ id: company } };
        this.setState({ companyData, loaded: true });
      });
  }

  setCompanyDetails(details) {
    const { companyId } = this.state;
    if (details) {
      this.setState({ saving: true });
      auth.app.firestore()
        .collection('companies')
        .doc(companyId)
        .update(details)
        .then(() => this.setState({ companyData: details, saving: false }));
    }
  }

  render() {
    const {
      companyData, loaded, saving
    } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {`${companyData.name} | Zetwerk`}
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <CompanyProfile companyData={companyData} />
              </Grid>
              <Grid
                style={{ opacity: saving ? 0.3 : 1 }}
                item
                lg={8}
                md={6}
                xs={12}
              >
                {loaded && <CompanyProfileDetails handler={(prop) => this.setCompanyDetails(prop)} companyData={companyData} />}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default Account;
