import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getInitials from 'src/utils/getInitials';
import auth from '../../firebase';

const CompanyProfile = ({ companyData, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [fileDialog, setFileDialog] = useState(false);
  const [fileInput, setFileInput] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [uploaded, setUploaded] = useState('');

  if (companyData.logo || uploaded.length !== 0) {
    auth.app.storage().ref(uploaded.length !== 0 ? uploaded : companyData.logo).getDownloadURL().then((url) => setImageUrl(url))
      .catch(() => null);
  }

  function uploadLink() {
    const storageRef = auth.app.storage().ref();

    if (fileInput.length !== 0) {
      // Loops through all the selected files
      // create a storage reference
      const storage = storageRef.child(`${fileInput[0].name}`);

      // upload file
      const upload = storage.put(fileInput[0]);

      // update progress bar
      upload.on(
        'state_changed',
        (snapshot) => {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },

        () => {
          alert('error uploading file');
        },

        () => {}
      );
      upload.then(() => {
        setImageUrl(null);
        auth.app.firestore()
          .collection('companies')
          .doc(companyData.id)
          .update({ logo: `${fileInput[0].name}` })
          .then(() => {
            setFileDialog(false);
            setUploaded(fileInput[0].name);
          });
      });
    } else {
      alert(t('common.noFile'));
    }
  }

  return (
    <Card {...rest}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={imageUrl}
            sx={{
              height: 100,
              width: 100
            }}
          >
            {getInitials(companyData.name)}
          </Avatar>
          <Typography
            style={{ paddingTop: 15 }}
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {companyData.name}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={() => setFileDialog(true)}
        >
          {t('common.uploadPicture')}
        </Button>
      </CardActions>
      <Dialog fullWidth open={fileDialog} onClose={() => setFileDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><Typography fontSize={20} fontWeight="500">{t('common.uploadFile')}</Typography></DialogTitle>
        <DialogContent>
          <div style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
            <Button
              variant="contained"
              component="label"
            >
              {t('common.fileSelect')}
              <input
                type="file"
                accept=".jpg,.png,.jpeg"
                onChange={(event) => setFileInput(event.target.files)}
                hidden
              />
            </Button>
            <span style={{ wordWrap: 'break-word', marginLeft: 6 }}>{fileInput && fileInput.length > 0 ? fileInput[0].name : t('common.noFile')}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFileDialog(false)} color="primary">
            {t('common.cancel')}
          </Button>
          <Button onClick={() => uploadLink()} color="primary">
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

CompanyProfile.propTypes = {
  companyData: PropTypes.object.isRequired
};

export default CompanyProfile;
