import { Helmet } from 'react-helmet';
import React, {useRef} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  Image as ImageIcon,
  Trash2 as TrashIcon
} from 'react-feather';
import { withTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { isMobile } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Clear';
import auth from '../firebase';
import JobList from '../components/projects/JobList';
import '../common/loadingStyle.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

class Project extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      projectId: '',
      users: [],
      orders: [],
      projectInfo: {},
      userDialog: false,
      projectDialog: false,
      fileDialog: false,
      galleryDialog: false,
      allUsers: [],
      searchValue: '',
      newUserValue: null,
      newProjectName: '',
      newProjectAddress: '',
      images: [],
      files: [],
      gallery: [],
      fileInput: null,
      progress: 0,
      photoIndex: 0,
      x: 0,
      y: 0
    };
  }

  componentDidMount() {
    // Get project ID from url
    const splitted = window.location.pathname.split('project/');
    this.setState({ projectId: decodeURI(splitted[1]) });
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData(decodeURI(splitted[1]));
        this.getProjectInfoUsers(decodeURI(splitted[1]));
      }
    });
  }

  async getUserData(projectId) {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const userData = userSnap.data();
        this.setState({ userData });
        sessionStorage.setItem('user', JSON.stringify({ ...userData, ...{ id: auth.currentUser.uid } }));
        this.getJobs(projectId, userData);
      });
  }

  /**
   * Get all project's jobs and sort by date
   *
   * @param {string} projectId - project id
   *
   * */
  getJobs(projectId, userData) {
    let query = auth.app.firestore().collection('jobs');
      query = query.where('project', '==', projectId);
      if (userData.role === 'factory_user') {
        query = query.where('factoryStatus', '!=', null);
      }
      query.get()
      .then((snap) => {
        const newOrders = [];
        snap.docs.forEach((doc) => {
          newOrders.push({ ...doc.data(), ...{ id: doc.id } });
        });
        this.setState({
          orders: newOrders.sort((a, b) => b.created.toDate() - a.created.toDate())
        });
      });
  }

  /**
   * Get project info and use info to get users
   *
   * @param {string} projectId - project id
   *
   * */
  getProjectInfoUsers(projectId) {
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .get()
      .then((snap) => {
        const data = snap.data();
        if (data) {
          const myRe = new RegExp('(.*/)*.+\.(png|jpg|gif|bmp|jpeg|PNG|JPG|GIF|BMP|JPEG)$', 'g');
          let images = data.files.filter((image) => myRe.test(image));
          if (data.gallery?.length > 0) {
            images = images.concat(data.gallery);
          }
          images = [...new Set(images)];
          const files = data.files.filter((file) => !file.includes(`${projectId}_`) && !images.includes(file));
          this.setState({
            projectInfo: data,
            files,
            images,
          });
          this.getUsers(data.users);
        }
      });
  }

  getGallery() {
    const {images} = this.state;
    this.setState({imagesLoading: true, photoIndex: 0});
    const promises = [];
    const gallery = [];
    images.forEach((image, index) => {
      promises.push(auth.app.storage().ref(image).getDownloadURL().then((url) => {
        gallery.push({
          photo: url,
          caption: image,
          number: index,
        });
      }));
    })

    Promise.all(promises).then(() =>this.setState({gallery: gallery.sort((a,b) => a.number - b.number), galleryDialog: true, imagesLoading: false}));
  }

  deleteActivePhoto() {
    const {gallery, photoIndex} = this.state;

    const galleryObject = gallery.find((o) => o.number === photoIndex);
    const imageName = galleryObject.caption;
    this.deleteFile(imageName);
    this.deleteImage(imageName);
    const newGallery = gallery.filter((o) => o.number !== photoIndex)?.sort((a,b) => a.number - b.number).map((g, index) => ({...g, number: index}));
    this.setState({gallery: newGallery, photoIndex: 0, galleryDialog: newGallery.length > 0});
  }

  /**
   * Get project users
   *
   * @param {Array} users - Array of user uid's
   *
   * */
  getUsers(users) {
    const newUsers = [];
    const promises = [];
    users.forEach((uid) => {
      promises.push(auth.app.firestore()
        .collection('users')
        .doc(uid)
        .get()
        .then((snapshot) => {
          newUsers.push({ ...snapshot.data(), ...{ id: snapshot.id } });
        }));
    });
    Promise.all(promises).then(() => this.setState({ users: newUsers }));
  }

  // Save new project data
  saveProjectData() {
    const {
      newProjectName, newProjectAddress, projectId, projectInfo
    } = this.state;
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ name: newProjectName, address: newProjectAddress })
      .then(() => {
        projectInfo.name = newProjectName;
        projectInfo.addresss = newProjectAddress;
        this.setState({ projectDialog: false, projectInfo });
      });
  }

  /**
   * Delete file
   *
   * @param {string} file - File ref id to delete
   *
   * */
  deleteFile(file) {
    const { files, projectId } = this.state;
    const newFiles = files.filter((f) => f !== file);
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ files: newFiles })
      .then(() => {
        this.setState({ files: newFiles });
      });
  }

  /**
   * Delete file
   *
   * @param {string} file - File ref id to delete
   *
   * */
  deleteImage(image) {
    const { images, projectId } = this.state;
    const newImages = images.filter((f) => f !== image);
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ gallery: newImages })
      .then(() => {
        this.setState({ images: newImages });
      });
  }
  
  /**
   * Download file
   *
   * @param {string} file - File ref id to download
   *
   * */
  // eslint-disable-next-line class-methods-use-this
  downloadFile(file) {
    auth.app.storage().ref(file).getDownloadURL().then((url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    });
  }

  /**
   * Upload project related files
   * - Update project files
   * - Store file in cloudstorage
   *
   * */
  uploadFile() {
    const { fileInput, files, images, projectId } = this.state;
    const storageRef = auth.app.storage().ref();

    if (fileInput.length !== 0) {
      // Loops through all the selected files
      for (let i = 0; i < fileInput.length; i++) {
        // create a storage reference
        const storage = storageRef.child(`${fileInput[i].name}`);

        // upload file
        const upload = storage.put(fileInput[i]);
        const myRe = new RegExp('(.*/)*.+\.(png|jpg|gif|bmp|jpeg|PNG|JPG|GIF|BMP|JPEG)$', 'g');
        if (myRe.test(fileInput[i].name)) {
          upload.then(() => {
            images.push(`${fileInput[i].name}`);
            auth.app.firestore()
              .collection('projects')
              .doc(projectId)
              .update({ gallery: images })
              .then(() => {
                this.setState({ images, fileDialog: false });
              });
          });
        } else {
          upload.then(() => {
            files.push(`${fileInput[i].name}`);
            auth.app.firestore()
              .collection('projects')
              .doc(projectId)
              .update({ files })
              .then(() => {
                this.setState({ files, fileDialog: false });
              });
          });
        }
      }
    } else {
      alert('No file chosen');
    }
  }

  /**
   * Add user to project
   * - Update project users
   * - Update user's projects
   *
   * */
  addUser() {
    const {
      newUserValue, allUsers, projectId, users, projectInfo
    } = this.state;
    const newUser = allUsers.find((u) => u.name === newUserValue);
    const newUsers = users;
    newUsers.push(newUser);
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ users: newUsers.map((usr) => usr.id) })
      .then(() => {
        const newProj = newUser.projects;
        const notificationCall = auth.app.functions().httpsCallable('sendusernotification');
        notificationCall({
          userId: newUser.id,
          projectId,
          projectName: projectInfo.name,
          message: `You have been added to project ${projectInfo.name}`
        });
        newProj.push(projectId);
        auth.app.firestore()
          .collection('users')
          .doc(newUser.id)
          .update({ projects: newProj });
      });
    this.setState({ userDialog: false, users: newUsers });
  }

  /**
   * Delete user from project
   * - Update project users
   * - Update user's projects
   *
   * @param {object} user - User that is deleted from the project
   *
   * */
  deleteUser(user) {
    const { projectId, users } = this.state;
    const newUsers = users.filter((u) => u.id !== user.id);
    auth.app.firestore()
      .collection('projects')
      .doc(projectId)
      .update({ users: newUsers.map((usr) => usr.id) })
      .then(() => {
        const newProj = user.projects.filter((p) => p !== projectId);
        auth.app.firestore()
          .collection('users')
          .doc(user.id)
          .update({ projects: newProj });
      });
    this.setState({ users: newUsers });
  }

  // Load user data depending on user role
  loadUsers() {
    const { userData } = this.state;
    let promise = Promise.resolve();
    if (userData.role === 'super_admin') {
      promise = promise.then(() => auth.app.firestore()
        .collection('users')
        .get());
    } else if (userData.role === 'company_admin') {
      promise = promise.then(() => auth.app.firestore()
        .collection('users')
        .where('company', '==', userData.company)
        .get());
    }
    promise.then((snap) => {
      const newUsers = [];
      snap.docs.forEach((doc) => {
        newUsers.push({ ...doc.data(), ...{ id: doc.id } });
      });
      this.setState({
        allUsers: newUsers,
        userDialog: true,
        newUserValue: null,
        searchValue: '',
      });
    });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      projectId, orders, imagesLoading, files, images, photoIndex, gallery, userData, users, projectInfo, userDialog, allUsers, searchValue, newUserValue, projectDialog, newProjectAddress, newProjectName, galleryDialog, fileDialog, fileInput, progress,
    } = this.state;
    const galleryUrls = gallery.map((g) => g.photo);
    const isFactory = userData.role === 'factory_user';

    return (
      <>
        <Helmet>
          <title>
            Project
            {' '}
            {projectId}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            {userData.role && <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                sm={6}
                xl={4}
                xs={12}
                height={isMobile ? 'auto' : '40vh'}
              >
                <Card sx={{ height: '100%', paddingBottom: 9 }}>
                  <CardHeader
                    title={t('common.details')}
                    action={(
                      <IconButton onClick={() => this.setState({ projectDialog: true, newProjectName: projectInfo.name, newProjectAddress: projectInfo.address })} aria-label="settings" color="primary">
                        <EditIcon />
                      </IconButton>
                    )}
                  />
                  <Divider />
                  <PerfectScrollbar>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b>{t('jobsAndProjects.projectName')}</b>
                            </TableCell>
                            <TableCell>
                              {projectInfo.name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t('jobsAndProjects.projectNumber')}</b>
                            </TableCell>
                            <TableCell>
                              {projectId}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t('common.companyName')}</b>
                            </TableCell>
                            <TableCell>
                              {projectInfo.customer}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>{t('common.address')}</b>
                            </TableCell>
                            <TableCell>
                              {projectInfo.address}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
              {!isFactory && <Grid
                item
                lg={4}
                sm={6}
                xl={4}
                xs={12}
                height={isMobile ? '50vh' : '40vh'}
              >
                <Card sx={{ height: '100%', paddingBottom: 9 }}>
                  <CardHeader
                    title={t('common.users')}
                    action={(
                      <IconButton onClick={() => this.loadUsers()} aria-label="settings" color="primary">
                        <AddIcon />
                      </IconButton>
                    )}
                  />
                  <Divider />
                  <PerfectScrollbar>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {t('common.name')}
                            </TableCell>
                            <TableCell>
                              {t('common.role')}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right', paddingRight: 2.5 }}>
                              {t('common.delete')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users.map((customer) => (
                            <TableRow
                              hover
                              key={customer.id}
                              selected={users.indexOf(customer.id) !== -1}
                            >
                              <TableCell onClick={() => null}>
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <Typography
                                    color="textPrimary"
                                    variant="body2"
                                  >
                                    {customer.name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                {customer.role && customer.role.replace('_', ' ')}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'right', paddingRight: 2.5 }}>
                                <IconButton sx={{ color: 'black', height: 35, width: 35 }}>
                                  <DeleteIcon onClick={() => this.deleteUser(customer)} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>}
              <Grid
                item
                lg={4}
                sm={6}
                xl={4}
                xs={12}
                height={isMobile ? '50vh' : '40vh'}
              >
                <Card sx={{ height: '100%', paddingBottom: 9 }}>
                  <CardHeader
                    title={t('common.files')}
                    action={(
                      <div>
                        {imagesLoading ?
                        <IconButton color="primary">
                          <div class="dot-flashing"></div>
                        </IconButton> :
                        <IconButton onClick={() => this.getGallery()} aria-label="settings" color="primary">
                          <ImageIcon />
                          <div style={{ position: 'absolute', fontSize: 12, borderRadius: '50%', backgroundColor: '#EE7022', width: 16, height: 16, color: 'white', top: 3, right: 3, padding: 1 }}>{images.length}</div>
                        </IconButton> }
                        <IconButton onClick={() => this.setState({fileDialog: true})} aria-label="settings" color="primary">
                          <AddIcon />
                        </IconButton>
                      </div>
                    )}
                  />
                  <Divider />
                  <PerfectScrollbar>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Table>
                        <TableBody>
                          {files && files.filter((f) => !(isFactory && f.includes('admin'))).map((file) => (
                            <TableRow
                              hover
                              key={file}
                              selected={users.indexOf(file) !== -1}
                            >
                              <TableCell sx={{ cursor: 'pointer' }} onClick={() => this.downloadFile(file)}>
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <Typography
                                    color="textPrimary"
                                    variant="body2"
                                  >
                                    {file}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'right', paddingRight: 2.5 }}>
                                <IconButton sx={{ color: 'black', height: 35, width: 35 }}>
                                  <DeleteIcon onClick={() => this.deleteFile(file)} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
                height="50vh"
              >
                <JobList refresh={() => this.getJobs(projectId)} orders={orders} isFactory={userData.role === 'factory_user'} />
              </Grid>
            </Grid>}
          </Container>
          <Dialog fullWidth open={userDialog} onClose={() => this.setState({ userDialog: false })} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><Typography fontSize={20} fontWeight="500">{t('users.add')}</Typography></DialogTitle>
            <DialogContent>
              <Autocomplete
                freeSolo
                id="name"
                disableClearable
                value={newUserValue}
                onChange={(_, newValue) => {
                  this.setState({ newUserValue: newValue });
                }}
                inputValue={searchValue}
                onInputChange={(_, newInputValue) => {
                  this.setState({ searchValue: newInputValue });
                }}
                options={allUsers.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('users.search')}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ userDialog: false })} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={() => this.addUser()} color="primary">
                {t('common.add')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth open={projectDialog} onClose={() => this.setState({ projectDialog: false })} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><Typography fontSize={20} fontWeight="500">{t('common.editDetails')}</Typography></DialogTitle>
            <DialogContent>
              <TextField
                sx={{ marginBottom: 3 }}
                fullWidth
                label={t('jobsAndProjects.projectName')}
                name="projectname"
                onChange={(event) => this.setState({ newProjectName: event.target.value })}
                value={newProjectName}
                variant="outlined"
              />
              <TextField
                fullWidth
                label={t('common.address')}
                name="address"
                onChange={(event) => this.setState({ newProjectAddress: event.target.value })}
                value={newProjectAddress}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ projectDialog: false })} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={() => this.saveProjectData()} color="primary">
                {t('common.save')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth open={fileDialog} onClose={() => this.setState({ fileDialog: false })} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><Typography fontSize={20} fontWeight="500">{t('common.uploadFile')}</Typography></DialogTitle>
            <DialogContent>
              <div style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
                <Button
                  variant="contained"
                  component="label"
                >
                  {t('common.fileSelect')}
                  <input
                    type="file"
                    onChange={(event) => this.setState({ fileInput: event.target.files })}
                    hidden
                  />
                </Button>
                <span style={{ wordWrap: 'break-word', marginLeft: 6 }}>{fileInput && fileInput.length > 0 ? fileInput[0].name : t('common.noFile')}</span>
                <span style={{ wordWrap: 'break-word', marginLeft: 6 }}>{progress !== 0 ? progress : null}</span>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ fileDialog: false })} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={() => this.uploadFile()} color="primary">
                {t('common.save')}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {galleryDialog && gallery?.length > 0 && (
        <Lightbox
          toolbarButtons={[
            <IconButton id='trash' ref='trash' onClick={() => this.deleteActivePhoto()} aria-busy="false" color="primary">
              <TrashIcon size={25} color="white" />
            </IconButton>,
         ]}
          reactModalStyle={{overlay: {zIndex: 9000}}}
          mainSrc={galleryUrls[photoIndex]}
          nextSrc={galleryUrls[(photoIndex + 1) % gallery.length]}
          prevSrc={galleryUrls[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => this.setState({ galleryDialog: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + gallery.length - 1) % gallery.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % gallery.length,
            })
          }
        />
        )}
      </>
    );
  }
}

// Connect global state and translation to class component
export default withTranslation()(Project);
