import { Helmet } from 'react-helmet';
import React from 'react';
import moment from 'moment';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { pdfjs, Document, Page } from 'react-pdf';
import auth from '../firebase';
import {factoryStatusses} from '../common/status.js'

// pdfjs.GlobalWorkerOptions.workerSrc = require('react-pdf/dist/esm/pdf.worker.entry.js');

class Job extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // Don't call this.setState() here!
    this.state = {
      userData: {},
      jobData: {},
      projectData: {},
      jobId: '',
      pdfUrl: '',
      numPages: null,
      pageNumber: 1,
      screenHeight: 0,
      position: 1,
      commentText: '',
    };
  }

  componentDidMount() {
    const splitted = window.location.pathname.split('job/');
    this.setState({ jobId: decodeURI(splitted[1]), screenHeight: window.innerHeight });
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getJobData(decodeURI(splitted[1]));
      }
    });
    this.getUserData();
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  async getUserData() {
    this.setState({ userData: JSON.parse(sessionStorage.getItem('user')) });
  }

  getJobData(jobId) {
    const { userData } = this.state;
    auth.app.firestore()
      .collection('jobs')
      .doc(jobId)
      .get()
      .then((snap) => {
        this.setState({ jobData: { ...snap.data(), ...{ id: jobId } } });
        auth.app.firestore()
          .collection('projects')
          .doc(snap.data().project)
          .get()
          .then((snapshot) => {
            let pdfPath = snap.data().pdf;
            if (userData.role !== 'factory_user' && snap.data().pdfAdmin) {
              pdfPath = snap.data().pdfAdmin;
            }
            auth.app.storage().ref(pdfPath).getDownloadURL().then((url) => {
              this.setState({ pdfUrl: url });
            });
            this.setState({ projectData: { ...snapshot.data(), ...{ id: snap.data().project } } });
          });
      });
  }

  sendComment() {
    const {commentText, position, jobData, userData, jobId} = this.state;
    if (commentText.length > 0) {
      const newMessage = {
        avatar: userData.avatar || '',
        comment: commentText,
        createdAt: new Date(),
        name: userData.name
      };
      let comments = [];
      if (jobData.comments?.length !== jobData.sheets.length) {
        Array.from(Array(jobData.sheets.length), (e, i) => {
            comments.push({
              messages: i === position - 1 ? [newMessage] : []
            })
        });
      } else {
        comments = jobData.comments;
        comments[position - 1].messages.push(newMessage);
      }  
      auth.app.firestore().collection('jobs').doc(jobId).update({
        comments
      });
      jobData.comments = comments;
      this.setState({jobData, commentText: ''});
    }
  }

  setStatus(status) {
    const { jobData, jobId, projectData } = this.state;
    const updateData = {
      status,
    };
    jobData.status = status;
    this.setState({ jobData });
    if (status === 'completed') {
      updateData.jobCompleted = new Date();
    }
    if (status === 'approved' && jobData.factoryStatus !== 'new') {
      this.setFactoryStatus('new');
    }
    auth.app.firestore().collection('jobs').doc(jobId).update(updateData);
    const notificationCall = auth.app.functions().httpsCallable('sendtopicnotification');
    notificationCall({
      projectId: projectData.id,
      projectName: projectData.name,
      status,
      message: this.props.t('notifications.statusChanged', { jobId, status: this.props.t(`notifications.${status}`) })
    });
  }

  chatRows() {
    const {position, jobData} = this.state;
    const comments = jobData?.comments?.[position - 1]?.messages;
    if (!comments) {
      return null;
    }
    comments.forEach(async (comment) => {
      comment.image = comment.avatar?.length > 0 ? await auth.app.storage().ref(comment.avatar).getDownloadURL() : null;
    });

    return comments.map((comment) => <Box sx={{
      alignItems: 'center',
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
    }}>
      <Avatar
        src={comment.image}
        sx={{
          height: 35,
          width: 35
        }}
      />
      <Box display='block' sx={{marginLeft: 1, marginRight: 1}}>
        <Typography variant="h7">{moment((comment.createdAt instanceof Date) ? comment.createdAt : comment.createdAt.toDate()).format('DD/MM/YYYY @ h:mm')} - {comment.name}</Typography>
        <Typography variant="h6">{comment.comment}</Typography>
      </Box>
    </Box>);
  }

  setFactoryStatus(factoryStatus) {
    const { jobData, jobId, projectData } = this.state;
    jobData.factoryStatus = factoryStatus;
    this.setState({ jobData });
    switch(factoryStatus) {
      case 'new':
        if (jobData.status !== 'approved') {
          this.setStatus('approved');
        }
        break;
      case 'inProgress':
        this.setStatus('inFactory');
        break;
      case 'ready':
        this.setStatus('completed');
        break;
      case 'pickedUp':
        const notificationCall = auth.app.functions().httpsCallable('sendtopicnotification');
        notificationCall({
          projectId: projectData.id,
          projectName: projectData.name,
          status,
          message: this.props.t('notifications.statusChanged', { jobId, status: this.props.t(`notifications.${factoryStatus}`) })
        });
      default:
        break;
    }
    auth.app.firestore().collection('jobs').doc(jobId).update({
      factoryStatus
    });

  }

  downloadPdf() {
    const { pdfUrl, jobData, userData } = this.state;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${userData.role !== 'factory_user' && jobData.pdfAdmin ? jobData.pdfAdmin : jobData.pdf}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      projectData, userData, jobData, jobId, pdfUrl, pageNumber, numPages, screenHeight, position, commentText
    } = this.state;
    const isFactory = userData?.role === 'factory_user';

    let selectableStatus = [jobData.status];

    const approvalStages = ['waitingForApproval', 'approved', 'rejected'];
    if (approvalStages.includes(jobData.status)) {
      selectableStatus = approvalStages;
    }

    let selectableStatusFactory = factoryStatusses;
    if (jobData.factoryStatus === 'ready' || jobData.factoryStatus === 'pickedUp') {
      selectableStatusFactory = ['ready', 'pickedUp'];
    }

    return (
      <>
        <Helmet>
          <title>
            Job
            {' '}
            {jobId}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            {userData && userData.role && jobData && jobData.status && <Grid
              container
              sx={{ py: 3, px: 3 }}
              spacing={3}
            >
              <Grid
                container
                lg={6}
                sm={6}
                xl={6}
                xs={12}
                sx={{ px: isMobile ? 0 : 3, marginBottom: 3 }}
              >
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                >
                  <Card>
                    <CardContent sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Document
                        options={{ workerSrc: 'pdf.worker.js' }}
                        file={pdfUrl}
                        onLoadSuccess={(e) => this.onDocumentLoadSuccess(e)}
                      >
                        <Page height={isMobile ? screenHeight * 0.56 : screenHeight * 0.76} pageNumber={pageNumber} />
                      </Document>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: 'lightgrey', color: 'black' }}
                        onClick={() => this.setState({ pageNumber: pageNumber > 1 ? (pageNumber - 1) : pageNumber })}
                      >
                        {t('jobsAndProjects.previous')}
                      </Button>
                      <Button
                        sx={{ marginLeft: 2 }}
                        style={{ backgroundColor: 'lightgrey', color: 'black' }}
                        variant="contained"
                        onClick={() => this.setState({ pageNumber: pageNumber < numPages ? (pageNumber + 1) : pageNumber })}
                      >
                        {t('jobsAndProjects.next')}
                      </Button>
                      <Button
                        sx={{ marginLeft: 2 }}
                        variant="contained"
                        onClick={() => this.downloadPdf()}
                      >
                        {t('jobsAndProjects.download')}
                      </Button>
                      <span style={{ marginLeft: 180 }}>
                        {t('jobsAndProjects.page')}
                        {' '}
                        {pageNumber}
                        {' '}
                        {t('jobsAndProjects.of')}
                        {' '}
                        {numPages}
                      </span>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid
                container
                lg={6}
                sm={6}
                xl={6}
                xs={12}
                direction={"row"}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <Card>
                      <PerfectScrollbar>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <b>{t('jobsAndProjects.jobName')}</b>
                                </TableCell>
                                <TableCell>
                                  {jobData.id}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <b>Project</b>
                                </TableCell>
                                <TableCell>
                                  {projectData.id} - {projectData.name}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <b>{t('common.companyName')}</b>
                                </TableCell>
                                <TableCell>
                                  {projectData.customer}
                                </TableCell>
                              </TableRow>
                              {!isFactory && <TableRow>
                                <TableCell>
                                  <b>{t('common.price')}</b>
                                </TableCell>
                                <TableCell>
                                  {jobData.cost ? `${jobData.cost} euro` : 'Job not ordered yet'}
                                </TableCell>
                              </TableRow>}
                            </TableBody>
                          </Table>
                        </Box>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                  <Grid
                      item
                      lg={6}
                      sm={6}
                      xl={6}
                      xs={12}
                    >
                      <Card>
                        <PerfectScrollbar>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('jobsAndProjects.profileTypes')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {jobData.sheets ? jobData.sheets.length : 0}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('jobsAndProjects.delivery')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {jobData.delivery ? jobData.delivery.toDate().toDateString() : null}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('common.created')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {jobData.created ? jobData.created.toDate().toDateString() : null}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <b>{t('common.address')}</b>
                                  </TableCell>
                                  <TableCell>
                                    {projectData.address}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </PerfectScrollbar>
                      </Card>
                    </Grid>
                  </Grid>
                  { !isFactory && (<Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Card>
                      <Box
                        sx={{
                          alignItems: 'center',
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <CardHeader
                          title="Status"
                          style={{minWidth: 127}}
                        />
                        {
                          selectableStatus.map((status) =>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={selectableStatus.length === 1}
                              onClick={() => this.setStatus(status)}
                              style={{
                                width: '100%',
                                marginRight: 10,
                                fontSize: status === 'waitingForApproval' ? 11 : 14,
                                height: 35,
                                backgroundColor: selectableStatus.length === 1 ? 'white' : jobData.status === status ? null : 'lightgrey',
                                color: selectableStatus.length === 1 ? '#EE7022' : jobData.status === status ? 'white' : 'black',
                                border: selectableStatus.length === 1 ? '1px solid #EE7022' : '',
                              }}
                            >
                              {t(`jobsAndProjects.${status}`)}
                            </Button>
                          )
                        }
                      </Box>
                    </Card>
                  </Grid>)}
                  {!['new', 'waitingForApproval', 'rejected'].includes(jobData.status) && (<Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                  >
                    <Card>
                      <Box
                        sx={{
                          alignItems: 'center',
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <CardHeader
                          title="Factorystatus"
                          style={{minWidth: 127}}
                        />
                        {
                          selectableStatusFactory.map((status) =>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.setFactoryStatus(status)}
                              style={{
                                width: '100%',
                                marginRight: 10,
                                fontSize: status === 'waitingForApproval' ? 11 : 14,
                                height: 35,
                                backgroundColor: jobData.factoryStatus === status ? null : 'lightgrey',
                                color: jobData.factoryStatus === status ? 'white' : 'black',
                              }}
                            >
                              {t(`jobsAndProjects.${status}`)}
                            </Button>
                          )
                        }
                      </Box>
                    </Card>
                  </Grid>)}
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    height='calc(100% - 410px)'
                    maxHeight='calc(100vh - 440px)'
                  >
                    <Card sx={{flex: 1, height: '100%', marginTop: isMobile ? 3 : 0 }}>
                      <Box
                          sx={{
                            alignItems: 'center',
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            margin: 2
                          }}
                        >
                        <Typography variant="h6">Comment for position</Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{height: '25px', marginTop: 0}}
                          value={position}
                          label="Position"
                          onChange={(value) => this.setState({position: value.target.value})}
                        >
                          {Array.from(Array(jobData.sheets.length), (e, i) => {
                            return <MenuItem value={i+1}>{i+1}</MenuItem>
                          })}
                        </Select>
                      </Box>
                      <Divider />
                        <Box
                          sx={{
                            height: 'calc(100% - 145px)',
                            maxHeight: 'calc(100% - 145px)',
                            marginLeft: 2,
                            marginTop: 2,
                            overflow: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          {this.chatRows()}
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 0.5,
                          }}
                        >
                        <TextField
                          fullWidth
                          sx={{marginLeft: 1, marginRight: 1, height: '60px', justifySelf: 'end', alignContent: 'flex-end'}}
                          multiline
                          label="Type comment"
                          value={commentText}
                          onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              this.sendComment();
                              ev.preventDefault();
                            }
                          }}
                          onChange={(value) => this.setState({commentText: value.target.value})}
                          InputProps={{
                              rows: 1
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.sendComment()}
                          sx={{
                            width: 100,
                            marginRight: 1,
                            height: '55px',
                            color: 'white'
                          }}
                        >SEND</Button>
                      </Box>
                    </Card>
                  </Grid>
              </Grid>
            </Grid>}
          </Container>
        </Box>
      </>
    );
  }
}

export default withTranslation()(Job);
