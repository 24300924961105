import React from 'react';
import { ThreeDots } from 'react-loading-icons';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import auth from '../../firebase';

class SubscriptionFormComponent extends React.Component {
  constructor(props) {
    super(props);
    const { lastInvoiceDocument, plan, userEmail, companyId } = this.props;
    this.state = {
      loading: false,
      planId: plan.planId, // Replace with the ID of your subscription plan
      userEmail,
      companyId,
      plan,
      userAmount: lastInvoiceDocument.users?.length >= plan.minimum ? lastInvoiceDocument.users?.length : plan.minimum,
    };
  }

  async handleSubmit() {
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      // Create a subscription
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        const createSubscriptionCall = auth.app.functions().httpsCallable('createMonthlySubscription');
        const subscription = await createSubscriptionCall({ planId: this.state.planId, quantity: 1, email: this.state.userEmail, paymentMethodId: result.paymentMethod.id })
          .then((result) => {
            return result.data;
          });

        // Check if 3D Secure authentication is required
        if (subscription.status === 'requires_action') {
          // You may need to redirect the customer to complete the authentication
          // The `next_action` object contains details for handling the authentication
          const { type, redirect_to_url } = subscription.latest_invoice.payment_intent.next_action;
          
          if (type === 'redirect_to_url') {
            // Redirect the customer to the URL for authentication
            window.location.href = redirect_to_url.url;
          }
        }
        // Redirect or handle the successful subscription creation
        const confirmPayment = await stripe.confirmCardPayment(
          subscription.clientSecret
        );
  
        await auth.app.firestore().collection('companies').doc(this.state.companyId).update({subscriptionId: subscription.subscriptionId, customerId: subscription.customerId});
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    return (
      <form style={{ width: '100%', justifyContent: 'center' }}>
        <CardElement />
        {this.state.loading ? (
          <ThreeDots style={{ width: '100%', marginTop: 20 }} stroke="black" />
        ) : (
          <button
            style={{
              width: '100%',
              marginTop: 20,
              color: 'white',
              fontSize: 14,
              padding: 8,
              fontWeight: 800,
              borderRadius: 3,
              backgroundColor: '#EE7022',
              border: 'none',
            }}
            disabled={!this.props.stripe}
            type="button"
            onClick={() => this.handleSubmit()}
          >
            Pay €{this.state.userAmount *  this.state.plan.cost}
          </button>
        )}
      </form>
    );
  }
}

export default function InjectedSubscriptionForm({ lastInvoiceDocument, plan, userEmail, companyId }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <SubscriptionFormComponent stripe={stripe} elements={elements} lastInvoiceDocument={lastInvoiceDocument} plan={plan} userEmail={userEmail} companyId={companyId} />
      )}
    </ElementsConsumer>
  );
}
