import React from 'react';

import { ThreeDots } from 'react-loading-icons'
import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';

class CheckoutFormComponent extends React.Component {
    constructor(props) {
        super(props);
        const { lastInvoiceDocument } = this.props;
        this.state = {
            loading: false,
            price: lastInvoiceDocument.totalInclTax,
        }
    }

    async handleSubmit() {
        const { stripe, elements } = this.props;


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }


        this.setState({
            loading: true,
        });

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: newurl,
            },
        });


        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };


    render() {
        return (
            <form style={{ width: '100%', justifyContent: 'center' }}>
                <PaymentElement />
                {this.state.loading ? <ThreeDots style={{ width: '100%', marginTop: 20 }} stroke="black" />
                    : <button style={{
                        width: '100%',
                        marginTop: 20,
                        color: 'white',
                        fontSize: 14,
                        padding: 8,
                        fontWeight: 800,
                        borderRadius: 3,
                        backgroundColor: '#EE7022',
                        border: 'none'
                    }} disabled={!this.props.stripe}
                    type='button'
                    onClick={() => this.handleSubmit()}>Pay €{this.state.price}</button>
                }
            </form>
        );
    }
}


export default function InjectedCheckoutForm({lastInvoiceDocument}) {
    return (
        <ElementsConsumer>
            {({
                stripe,
                elements,
            }) => (
                <CheckoutFormComponent stripe={stripe} elements={elements} lastInvoiceDocument={lastInvoiceDocument} />
            )}
        </ElementsConsumer>
    )
}
