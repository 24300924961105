import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import auth from '../firebase';

class Archived extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      jobs: []
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
      }
    });
  }

  async getUserData() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const userData = userSnap.data();
        this.setState({ userData });
        this.setJobs(userData);
        sessionStorage.setItem('user', JSON.stringify({ ...userData, ...{ id: auth.currentUser.uid } }));
        const event = document.createEvent('Event');
        event.initEvent('user-data-loaded', true, true);
        window.dispatchEvent(event);
      });
  }

  async setJobs(userData) {
    let query = auth.app.firestore().collection('jobs');
    query = query.where('company', '==', userData.company);
    query = query.where('factoryStatus', '==', 'pickedUp');
    if (userData.role === 'factory_user') {
      query = query.where('factoryStatus', '!=', null);
    }
    query.get()
      .then((response) => {
        const jobs = [];
        response.docs.forEach((doc) => {
          jobs.push({ ...doc.data(), ...{ id: doc.id } });
        });
        this.setState({ jobs });
      });
  }

  render() {
    const {
      jobs, userData
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Archived Jobs | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            {userData && userData.role && <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <LatestOrders currentJobs={jobs} isFactory={true} archived={true} />
              </Grid>
            </Grid>}
          </Container>
        </Box>
      </>
    );
  }
}

export default Archived;
