/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/core/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, experimentalStyled as styled } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { login } from '../auth';
import firebase from '../firebase';
import logo from '../assets/zetwerkLogo.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import { tiers } from '../common/tiers';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Zetwerk
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? grey[50] : grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    paddingRight: 20,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    resize: 'contain',
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#EE7022'),
  backgroundColor: '#EE7022',
  '&:hover': {
    backgroundColor: '#EE7022',
  },
}));

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#EE7022',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#EE7022',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#EE7022',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#EE7022',
    },
  },
});

export default function SignInSide() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n, language } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const reg = useRef(null);

  const [form, setForm] = useState({
    email: '',
    password: ''
  });

  const [pricing, setPricing] = useState(false);

  const [pass, setPass] = useState({
    newPass: '',
    repeatPass: ''
  });

  const [registerData, setRegisterData] = useState({
    adminName: '',
    email: '',
    address: '',
    phone: '',
    disabled: true,
    logo: '',
    VAT: '',
    created: new Date(),
    new: true,
    projects: [],
    invoices: [],
  });

  const [image, setImage] = useState('');

  const [selectedTier, setSelectedTier] = useState('');

  const [errorMessage, setErrorMesssage] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [register, setRegister] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  if (sessionStorage.getItem('user')) {
    navigate('/app/dashboard', { replace: true });
  }

  function goToDashboard() {
    firebase.app.firestore()
      .collection('users')
      .doc(firebase.app.auth().currentUser.uid)
      .get()
      .then((userSnap) => {
        const userData = userSnap.data();
        sessionStorage.setItem('user', JSON.stringify({ ...userData, ...{ id: firebase.app.auth().currentUser.uid } }));
      })
      .then(() => {
        navigate('/app/dashboard', { replace: true });
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(form).then((response) => {
      firebase.app.firestore()
        .collection('users')
        .doc(response.uid)
        .get()
        .then((snap) => {
          if (!['company_admin', 'super_admin', 'factory_user'].includes(snap.data().role)) {
            setEmailError(true);
          } else if (snap.data().firstLogin) {
            setPasswordReset(true);
          } else {
            if (
              snap.data().language
              && snap.data().language !== language
            ) {
              i18n.changeLanguage(snap.data().language);
            }
            goToDashboard();
          }
        });
    }).catch((error) => {
      if (error.code === 'auth/wrong-password') {
        setPasswordError(true);
      } else if (error.code === 'auth/invalid-email') {
        setEmailError(true);
      }
      setErrorMesssage(error.message);
    });
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    if (pass.repeatPass !== pass.newPass || pass.newPass.length === 0) {
      setPasswordConfirmError(true);
    } else {
      firebase.app.auth().currentUser.updatePassword(pass.newPass).then(() => firebase.app.firestore()
        .collection('users')
        .doc(firebase.app.auth().currentUser.uid).update({ firstLogin: false })).then(() => goToDashboard());
    }
  };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const forgotPassword = (Email) => {
    const valid = validateEmail(Email);

    if (valid) {
      firebase.app.auth().sendPasswordResetEmail(Email)
        .then(() => {
          setConfirmMessage(t('login.resetPasswordEmail'));
        }).catch((e) => {
          console.log(e);
        });
    } else {
      setEmailError(true);
      setErrorMesssage(t('login.invalidEmail'));
    }
  };

  const registerCompany = (e) => {
    e.preventDefault();
    setRegistered(true);
    setLoading(true);
    const valid = validateEmail(registerData.email);

    if (valid) {
      const compAdd = firebase.app.functions().httpsCallable('addcompany');
      setLoading(false);
      compAdd({...registerData, subscription: selectedTier.toLowerCase()}).then(() => { setConfirmMessage(t('login.registrationMessage')); });
    } else {
      setEmailError(true);
      setErrorMesssage(t('login.invalidEmail'));
    }
  };

  const fakeInputStyle = {
    opacity: 0, float: 'left', border: 'none', height: '0', width: '0'
  };
  if (image.length === 0) {
    firebase.app.storage().ref().child('/login').listAll()
      .then((res) => {
        res.items[Math.floor(Math.random() * res.items.length)].getDownloadURL().then((url) => {
          setImage(url);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} style={{ backgroundImage: image ? `url(${image})` : null }} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.avatar}>
            <img width="300" src={logo} alt="" />
          </div>
          {errorMessage
            && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
          {confirmMessage
            && <h5 style={{ color: 'green' }}>{confirmMessage}</h5>}
          {loading && <CircularProgress />}
          {registered ? null
            : passwordReset
              ? (
                <form id="reset" autoComplete="off" className={classes.form} noValidate onSubmit={handleConfirmPassword}>
                  <input type="password" name="fake-password" autoComplete="new-password" tabIndex="-1" style={fakeInputStyle} />
                  <input type="password" name="fake-password" autoComplete="new-password" tabIndex="-1" style={fakeInputStyle} />
                  <CssTextField
                    margin="normal"
                    required
                    autoComplete="off"
                    fullWidth
                    id="newPass"
                    label={t('login.newPass')}
                    type="password"
                    name="newPass"
                    error={passwordConfirmError}
                    onChange={(e) => setPass({ ...pass, newPass: e.target.value })}
                    autoFocus
                  />
                  <CssTextField
                    margin="normal"
                    required
                    fullWidth
                    name="repeatPass"
                    autoComplete="off"
                    onChange={(e) => setPass({ ...pass, repeatPass: e.target.value })}
                    label={t('login.repeatPass')}
                    type="password"
                    error={passwordConfirmError}
                    id="repeatPass"
                  />
                  <ColorButton
                    onClick={() => null}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 15 }}
                    className={classes.submit}
                  >
                    {t('login.changePass')}
                  </ColorButton>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              ) : register
                ? (
                  <form id="reset" ref={reg} autoComplete="off" className={classes.form} onSubmit={registerCompany}>
                    {registerError && <Alert severity="error" variant="outlined" sx={{marginBottom: 3}} color="error">
                      <AlertTitle>Error</AlertTitle>
                      Fill in <strong>all</strong> information to register your company
                    </Alert>}
                    {pricing ? (<div>
                    <Alert severity="info" variant="outlined" sx={{marginBottom: 5, marginLeft: 3, marginRight: 3}} color="warning">
                    <AlertTitle>Info</AlertTitle>
                      You will be able to use a <strong>14 day trial</strong> — <a style={{color:'#EE7022'}} href="https://zetwerk.app/pricing/">Pricing details</a>
                    </Alert>
                    <Container component="main">
                      <Grid container spacing={3} alignItems="flex-end">
                        {tiers.map((tier) => (
                          // Enterprise card is full width at sm breakpoint
                          <Grid
                            item
                            key={tier.title}
                            xs={12}
                            md={4}
                          >
                            <Card border sx={{height: 220, cursor: 'grab' }} onClick={() => setSelectedTier(tier.title)}>
                              <CardHeader
                                title={tier.title}
                                titleTypographyProps={{ align: 'center' }}
                                subheaderTypographyProps={{
                                  align: 'center',
                                }}
                                sx={{
                                  backgroundColor: (theme) =>
                                  selectedTier === tier.title
                                    ? '#EE7022'
                                    : theme.palette.grey[200],
                                  color: (theme) =>
                                  selectedTier === tier.title
                                    ? 'white'
                                    : 'black',
                                }}
                              />
                              <CardContent>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    mb: 2,
                                  }}
                                >
                                  <Typography component="h2" variant="h3" color="text.primary">
                                  €{tier.price}
                                  </Typography>
                                  <Typography variant="h6" color="text.secondary">
                                    /user/mo
                                  </Typography>
                                </Box>
                                <ul>
                                  {tier.description.map((line) => (
                                    <Typography
                                      variant="subtitle1"
                                      align="center"
                                      key={line}
                                    >
                                      {line}
                                    </Typography>
                                  ))}
                                </ul>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Container>
                    <ColorButton
                      onClick={() => null}
                      type="submit"
                      fullWidth
                      disabled={!selectedTier.length}
                      variant="contained"
                      color={selectedTier.length ? "primary" : "grey"}
                      style={{ marginTop: 25 }}
                      className={classes.submit}
                    >
                      {t('login.register')}
                    </ColorButton></div>): (<div>
                    <input type="password" name="fake-password" autoComplete="new-password" tabIndex="-1" style={fakeInputStyle} />
                    <input type="password" name="fake-password" autoComplete="new-password" tabIndex="-1" style={fakeInputStyle} />
                    <CssTextField
                      margin="normal"
                      required
                      autoComplete="off"
                      fullWidth
                      id="adminName"
                      label={t('common.name')}
                      name="adminName"
                      onChange={(e) => setRegisterData({ ...registerData, adminName: e.target.value })}
                      autoFocus
                    />
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      name="name"
                      autoComplete="off"
                      onChange={(e) => setRegisterData({ ...registerData, name: e.target.value })}
                      label={t('common.companyName')}
                      id="name"
                    />
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      error={emailError}
                      autoComplete="off"
                      onChange={(e) => setRegisterData({ ...registerData, email: e.target.value.replace(/\s/g, '') })}
                      label={t('common.email')}
                      value={registerData.email}
                      id="email"
                    />
                    <div style={{flexDirection: 'row', justifyContent: 'space-between', minWidth: '100%'}}>
                      <CssTextField
                        margin="normal"
                        sx={{width: '48%', marginRight: '2%'}}
                        required
                        name="phone"
                        autoComplete="off"
                        onChange={(e) => setRegisterData({ ...registerData, phone: e.target.value })}
                        label={t('login.contact')}
                        id="phone"
                      />
                      <CssTextField
                        margin="normal"
                        required
                        sx={{width: '48%', marginLeft: '2%'}}
                        name="VAT"
                        autoComplete="off"
                        onChange={(e) => setRegisterData({ ...registerData, VAT: e.target.value })}
                        label={t('company.vat')}
                        id="VAT"
                      />
                    </div>
                    <ColorButton
                      onClick={() => {
                        const valid = reg.current.checkValidity();
                        if (valid) {
                          setRegisterError(false);
                          setPricing(true);
                        } else {
                          setRegisterError(true);
                        }
                      }}
                      fullWidth
                      type='button'
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 15 }}
                      className={classes.submit}
                    >
                      Next
                    </ColorButton>
                    </div>)}
                    <Box mt={5}>
                      <Copyright />
                    </Box>
                  </form>
                )
                : (
                  <form id="login" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={t('common.emailAddress')}
                      name="email"
                      error={emailError}
                      onChange={(e) => setForm({ ...form, email: e.target.value.replace(/\s/g, '') })}
                      value={form.email}
                      autoComplete="email"
                      autoFocus
                    />
                    <CssTextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      onChange={(e) => setForm({ ...form, password: e.target.value })}
                      label={t('login.password')}
                      type="password"
                      error={passwordError}
                      id="password"
                      autoComplete="current-password"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          value="remember"
                          sx={{
                            '&.Mui-checked': {
                              color: '#EE7022',
                            },
                          }}
                        />
            )}
                      label={t('login.remember')}
                    />
                    <ColorButton
                      onClick={() => null}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {t('login.signIn')}
                    </ColorButton>
                    <Grid container>
                      <Grid item xs>
                        <Link href="#" onClick={() => forgotPassword(form.email)} style={{ color: 'black' }} variant="body2">
                          {t('login.forgotPass')}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" onClick={() => setRegister(true)} style={{ color: 'black' }} variant="body2">
                          {t('login.registerHere')}
                        </Link>
                      </Grid>
                    </Grid>
                    <Box mt={5}>
                      <Copyright />
                    </Box>
                  </form>
                )}
        </div>
      </Grid>
    </Grid>
  );
}
