import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

const Sales = ({ jobs, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const month = [];
  month[0] = 'JANUARY';
  month[1] = 'FEBRUARY';
  month[2] = 'MARCH';
  month[3] = 'APRIL';
  month[4] = 'MAY';
  month[5] = 'JUNE';
  month[6] = 'JULY';
  month[7] = 'AUGUST';
  month[8] = 'SEPTEMBER';
  month[9] = 'OCTOBER';
  month[10] = 'NOVEMBER';
  month[11] = 'DECEMBER';

  const currentDate = moment();
  const startDates = [
    moment().subtract(5, 'months'),
    moment().subtract(4, 'months'),
    moment().subtract(3, 'months'),
    moment().subtract(2, 'months'),
    moment().subtract(1, 'months'),
    currentDate
  ];

  const costs = [];
  const amounts = [];

  startDates.forEach((dat) => {
    let monthCost = 0;
    const filtered = jobs.filter((j) => j.delivery && moment(j.delivery.toDate()).isSame(dat, 'month'));
    filtered.forEach((j) => {
      if (j.cost) {
        monthCost += j.cost;
      }
    });
    costs.push(monthCost);
    amounts.push(filtered.length);
  });

  const data = {
    datasets: [
      /* {
        backgroundColor: colors.indigo[500],
        data: costs,
        label: 'Job Costs'
      }, */
      {
        backgroundColor: colors.indigo[500],
        data: amounts,
        label: t('dashboard.jobDeliveries')
      }
    ],
    labels: startDates.map((dat) => month[dat.month()])
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...rest}>
      <CardHeader
        title={t('dashboard.jobDeliveryPerMonth')}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Sales.propTypes = {
  jobs: PropTypes.array.isRequired,
};

export default Sales;
