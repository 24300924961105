import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  Container,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  Grid,
  Chip,
  IconButton,
  TableSortLabel,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon, Zap as EnableIcon } from 'react-feather';
import { green } from '@material-ui/core/colors';
import auth from '../../firebase';

const ProjectListResults = ({
  userData, companies, ...rest
}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [vat, setVat] = useState('');
  const [phone, setPhone] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = companies.map((c) => c.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const search = (event) => {
    const txt = event.target.value.toLowerCase();
    setSearchInput(txt);
    if (txt && txt.length > 0) {
      setFilteredCustomers(companies.filter((c) => c.name.toLowerCase().includes(txt) || c.id.toLowerCase().includes(txt)));
    } else {
      setFilteredCustomers([]);
    }
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function deleteCompanies() {
    setLoading(true);
    setDeleteModal(false);
    const deleteCall = auth.app.functions().httpsCallable('deletecompanies');
    deleteCall({ uids: selectedCustomerIds }).finally(() => {
      rest.refresh();
      setLoading(false);
      setSelectedCustomerIds([]);
    });
  }

  function enable(comp) {
    setLoading(true);
    const promises = [];
    if (comp.new) {
      const addcompanyadmin = auth.app.functions().httpsCallable('addcompanyadmin');
      promises.push(addcompanyadmin(comp));
    }
    promises.push(auth.app.firestore().collection('users').where('company', '==', comp.id).get()
      .then((snap) => {
        snap.docs.forEach((d) => {
          const enableCall = auth.app.functions().httpsCallable('enableuser');
          d.ref.update({ disabled: false });
          return enableCall({ uid: d.id });
        });
      }));
    promises.push(auth.app.firestore().collection('projects').where('customer', '==', comp.id).get()
      .then((snap) => {
        snap.docs.forEach((d) => {
          d.ref.update({ disabled: false });
        });
      }));
    promises.push(auth.app.firestore().collection('companies').doc(comp.id).update({ disabled: false, new: false, created: typeof comp.created === 'object' ? new Date() : comp.created }));
    Promise.all(promises).finally(() => {
      rest.refresh();
      setLoading(false);
    });
  }

  function disable() {
    setLoading(true);
    const promises = [];
    selectedCustomerIds.forEach((uid) => {
      promises.push(auth.app.firestore().collection('users').where('company', '==', uid).get()
        .then((snap) => {
          snap.docs.forEach((d) => {
            const disableCall = auth.app.functions().httpsCallable('disableuser');
            d.ref.update({ disabled: true });
            return disableCall({ uid: d.id });
          });
        }));
      promises.push(auth.app.firestore().collection('projects').where('customer', '==', uid).get()
        .then((snap) => {
          snap.docs.forEach((d) => {
            d.ref.update({ disabled: true });
          });
        }));
      promises.push(auth.app.firestore().collection('companies').doc(uid).update({ disabled: true }));
    });
    Promise.all(promises).finally(() => {
      rest.refresh();
      setLoading(false);
      setSelectedCustomerIds([]);
    });
  }

  function loadNewProjectDetails() {
    setLoading(false);
    setModalOpened(true);
  }

  function compare(a, b, type, ord) {
    const str = (typeof a[type] === 'string' || a[type] instanceof String);
    const aVar = str ? a[type].toLowerCase() : a[type];
    const bVar = str ? b[type].toLowerCase() : b[type];
    if (aVar < bVar) {
      return ord ? -1 : 1;
    }
    if (aVar > bVar) {
      return ord ? 1 : -1;
    }
    return 0;
  }

  function changeSort(type) {
    if (type) {
      if (orderBy === type) {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(type);
        const newCompanies = companies;
        newCompanies.sort((a, b) => compare(a, b, type, newOrder === 'asc'));
        setFilteredCustomers(newCompanies);
      } else {
        setOrderBy(type);
        const newCompanies = companies;
        newCompanies.sort((a, b) => compare(a, b, type, order === 'asc'));
        setFilteredCustomers(newCompanies);
      }
    }
  }

  function addProject() {
    setLoading(true);
    setModalOpened(false);
    return auth.app.firestore().collection('companies').doc(name.replace(/\s/g, '')).set({
      address,
      phone,
      created: new Date(),
      invoices: [],
      projects: [],
      logo: '',
      email,
      name,
      disabled: false,
      vat
    })
      .then(() => {
        rest.refresh();
        setLoading(false);
      });
  }

  return (
    <Container maxWidth={false}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {selectedCustomerIds && selectedCustomerIds.length > 0
            ? (
              <div>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => setDeleteModal(true)}
                >
                  {t('company.delete')}
                </Button>
                <Button
                  style={{ marginLeft: 12, backgroundColor: 'grey' }}
                  variant="contained"
                  onClick={() => disable()}
                >
                  {t('company.disable')}
                </Button>
              </div>
            ) : (
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: 12 }}
                onClick={() => loadNewProjectDetails()}
              >
                {t('company.add')}
              </Button>
            )}
        </Box>
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  onChange={search}
                  placeholder={t('company.search')}
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Card>
          <PerfectScrollbar>
            <Box sx={{ minWidth: isMobile ? 200 : 500 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.length === companies.length}
                        color="primary"
                        indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < companies.length
                    }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell sx={{ maxWidth: isMobile ? 75 : 400 }}>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => changeSort('name')}
                      >
                        {t('common.name')}
                      </TableSortLabel>
                    </TableCell>
                    {!isMobile && (
                    <TableCell sx={{ maxWidth: isMobile ? 75 : 400 }}>
                      {t('common.phone')}
                    </TableCell>
                    )}
                    {!isMobile && (
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'email'}
                        direction={orderBy === 'email' ? order : 'asc'}
                        onClick={() => changeSort('email')}
                      >
                        {t('common.email')}
                      </TableSortLabel>
                    </TableCell>
                    )}
                    {!isMobile && (
                    <TableCell sx={{ maxWidth: isMobile ? 75 : 400 }} />
                    )}
                    <TableCell sx={{ textAlign: 'right' }}>
                      <TableSortLabel
                        active={orderBy === 'createdDate'}
                        direction={orderBy === 'createdDate' ? order : 'asc'}
                        onClick={() => changeSort('createdDate')}
                      >
                        {t('company.creation')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ opacity: loading ? 0.3 : 1 }}>
                  {(searchInput.length > 0 ? filteredCustomers : companies).slice(0 + page * limit, limit * (page + 1)).map((project) => (
                    <TableRow
                      sx={{ cursor: project.disabled ? 'auto' : 'pointer', backgroundColor: project.disabled ? 'rgba(245,245,245 , 0.5 )' : null }}
                      hover
                      key={project.id}
                      selected={selectedCustomerIds.indexOf(project.id) !== -1}
                    >
                      <TableCell sx={{ maxWidth: isMobile ? 20 : 200 }} padding="checkbox">
                        {project.disabled ? (
                          <IconButton onClick={() => enable(project)} aria-label="settings" color={green[600]}>
                            <EnableIcon
                              size={20}
                              style={{ color: green[900], opacity: 1 }}
                            />
                          </IconButton>
                        )
                          : (
                            <Checkbox
                              color="primary"
                              checked={selectedCustomerIds.indexOf(project.id) !== -1}
                              onChange={(event) => handleSelectOne(event, project.id)}
                              value="true"
                            />
                          )}
                      </TableCell>
                      <TableCell onClick={() => window.location.assign(`company/${project.id}`)}>
                        <Box
                          sx={{
                            opacity: project.disabled ? 0.5 : 1,
                            alignItems: 'center',
                            display: 'flex',
                            maxWidth: isMobile ? 20 : 200,
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            variant={isMobile ? 'body2' : 'body1'}
                          >
                            {project.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      {!isMobile && (
                      <TableCell sx={{ opacity: project.disabled ? 0.5 : 1, maxWidth: isMobile ? 20 : 200 }}>
                        {project.phone}
                      </TableCell>
                      )}
                      {!isMobile && (
                      <TableCell sx={{ opacity: project.disabled ? 0.5 : 1 }}>
                        {project.email}
                      </TableCell>
                      )}
                      {!isMobile && (
                      <TableCell sx={{ maxWidth: isMobile ? 20 : 200 }}>
                        {project.new
                        && (
                        <Chip
                          color="primary"
                          label={t('company.new')}
                          size="small"
                        />
                        ) }
                      </TableCell>
                      )}
                      <TableCell sx={{ maxWidth: isMobile ? 20 : 200, opacity: project.disabled ? 0.5 : 1, textAlign: 'right' }}>
                        {project.created && (!project.new || !isMobile) && moment(project.createdDate).format('DD/MM/YYYY')}
                        {project.new && isMobile && (
                        <Chip
                          color="primary"
                          label={t('company.new')}
                          size="small"
                        />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            labelRowsPerPage={isMobile ? t('company.per') : t('company.rowsPerPage')}
            count={searchInput.length > 0 ? filteredCustomers.length : companies.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Box>
      <Dialog
        onBackdropClick={() => setModalOpened(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 20,
          minWidth: '100vw',
          justifyContent: 'center'
        }}
        open={modalOpened}
      >
        <Grid
          container
          width={isMobile ? '90vw' : null}
          spacing={0}
          sx={{ backgroundColor: 'white' }}
        >
          <form
            noValidate
          >
            <Card>
              <CardHeader
                subheader={t('company.details')}
                title={t('company.new')}
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.companyName')}
                      name="companyname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.email')}
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.address')}
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('common.phone')}
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label={t('company.vat')}
                      name="vat"
                      value={vat}
                      onChange={(e) => setVat(e.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={{ backgroundColor: 'lightgrey', color: 'black' }}
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  onClick={() => setModalOpened(false)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => addProject()}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Card>
          </form>
        </Grid>
      </Dialog>
      <Dialog
        onBackdropClick={() => setDeleteModal(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 20,
          minWidth: '100vw',
          justifyContent: 'center'
        }}
        open={deleteModal}
      >
        <Grid
          container
          width={isMobile ? '90vw' : null}
          spacing={0}
          sx={{ backgroundColor: 'white' }}
        >
          <Card>
            <CardHeader
              titleTypographyProps={{ fontSize: 20, marginBottom: 2 }}
              subheader={t('company.warningText')}
              title={t('company.warningTitle')}
            />
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                style={{ backgroundColor: 'lightgrey', color: 'black' }}
                sx={{ marginRight: 2 }}
                variant="contained"
                onClick={() => setDeleteModal(false)}
              >
                {t('common.cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => deleteCompanies()}
              >
                {t('common.save')}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Dialog>
    </Container>
  );
};

ProjectListResults.propTypes = {
  projects: PropTypes.array.isRequired,
  userData: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
};

export default ProjectListResults;
