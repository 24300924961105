import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import AccountProfile from 'src/components/account/AccountProfile';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
import auth from '../firebase';

class Account extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      userData: {}, loaded: false, saving: false, companies: []
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(() => {
      if (auth.currentUser) {
        this.getUserData();
        this.getCompanies();
      }
    });
  }

  getCompanies() {
    const companies = [];
    const userData = JSON.parse(sessionStorage.getItem('user'));
    if (userData.role === 'super_admin') {
      auth.app.firestore()
        .collection('companies')
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            companies.push(doc.id);
          });
          this.setState({ companies });
        });
    } else {
      this.setState({ companies: [userData.company] });
    }
  }

  async getUserData() {
    auth.app.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .get()
      .then((userSnap) => {
        const userData = { ...userSnap.data(), ...{ id: auth.currentUser.uid } };
        this.setState({ userData, loaded: true });
        sessionStorage.setItem('user', JSON.stringify(userData));
      });
  }

  setUserDetails(details) {
    if (details) {
      this.setState({ saving: true });
      auth.app.firestore()
        .collection('users')
        .doc(auth.currentUser.uid)
        .update(details)
        .then(() => this.setState({ userData: details, saving: false }));
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      userData, loaded, saving, companies
    } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {t('common.account')}
            {' '}
            | Zetwerk
          </title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <AccountProfile userData={userData} />
              </Grid>
              <Grid
                style={{ opacity: saving ? 0.3 : 1 }}
                item
                lg={8}
                md={6}
                xs={12}
              >
                {loaded && <AccountProfileDetails handler={(prop) => this.setUserDetails(prop)} userData={userData} companies={companies} currentUser={userData} />}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }
}

export default withTranslation()(Account);
